<template>
  <div class="widget-container">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div class="header">
      <h4>Vertragsdetails</h4>
    </div>
    <div class="body">
      <div v-if="!subscriptionStatus">
        Sie haben noch keinen Vertrag abgeschlossen. Besuchen Sie uns <a href="https://immoscape.de">auf unserer Homepage</a> für weitere Infos.
      </div>
      <div v-if="subscriptionStatus">
        <div class="row">
          <span class="col-6">
            <b>Abonnement:</b>
          </span>
          <span class="col-6">
            {{ subscriptionName }}
          </span>
        </div>      
        <div class="row">
          <span class="col-6">
            <b>Status:</b>
          </span>
          <span class="col-6">
            {{ subscriptionStatus }}
          </span>
        </div>      
        <div class="row">
          <span class="col-6">
            <b>Laufzeit:</b>
          </span>
          <span class="col-6">
            {{ subscriptionEndsAt }}
          </span>
        </div>
        <div class="row">        
          <div class="offset-6 col-5 gx-1 mt-2">
            <button
              class="btn btn-brand form-control"
              type="button"
              @click="triggerEditSubscription"
            >
              Verwalten
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import Subscription from '@/diaspora/models/subscription.js'

import { readProfileSubscription, getProfileStripeSubscriptionURL } from '@/diaspora/models/queries/subscriptions.js'

import FormMessages from '@/components/forms/features/form-messages.js'

export default {
  name: 'SubscriptionWidget',
  data(){
    return {
      subscriptionModel: new Subscription(),
      messages: new FormMessages()
    }
  },
  computed:{
    fields(){
      return this.subscriptionModel.fields
    },
    subscriptionName(){
      return this.fields.name.value
    },
    subscriptionStatus(){
      return this.fields.status.displayValue()
    },
    subscriptionEndsAt(){
      return services.$format.date(this.fields.currentPeriodEnd.value)
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    }
  },
  async created(){
    await this.readProfileSubscription()
  },
  methods:{
    async readProfileSubscription(){
      let subscriptionJSON = {}
      try{
        subscriptionJSON = await readProfileSubscription()
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("IA0008", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.subscriptionModel.unmarshalJSON(subscriptionJSON)
      return true
    },
    async triggerEditSubscription(){
      let stripeURL = ""
      try{
        stripeURL = await getProfileStripeSubscriptionURL()
      }catch(requestErr) {
        const parsedError = services.$err.parseRequestError("IA0010", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      location.href = stripeURL
    }
  },
  components: {
  }
}
</script>

<style>
</style>