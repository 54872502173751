import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import PasswordField from '@/diaspora/fields/password_field.js'

class CredentialsImmoscout extends Model{
    constructor() {
        super() 
        this.fields = {
            consumerKey:        new StringField(true),
            consumerSecret:     new PasswordField(true),
            authorizationUrl:   new StringField(),
            finalTokenValue:    new StringField(),
        }
    }
}

export default CredentialsImmoscout