<template>
  <select 
    :name="name"
    :placeholder="placeholder"
    :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    v-model.lazy="localValue"
  >
    <option
      v-for="option in localOptions"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'SelectInput',
  data(){
    return {
      localValue: '',
      isValid: null
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    options:{
      type: Array,
      required: true
    },
    optionGroup: {
      type: String
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "n.v.",
    },
    mandatory:{
      type: Boolean
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue'
  ],
  computed:{
    defaultOption(){
      if (!this.options || this.options.length < 1){
        return { label: 'n.v.', value: ''}
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.default === true){
          return option
        }
      }
      if (this.mandatory === true){
        return this.options[0]
      }
      return { label: 'n.v.', value: ''}
    },
    localOptions(){
      if (!this.defaultOption){
        return []
      }
      if (!this.selectableOptions){
        return [this.defaultOption]
      }
      if (this.defaultOption.value !== ''){
        return this.selectableOptions
      }
      return [this.defaultOption, ...this.selectableOptions]
    },
    selectableOptions(){
      const currentGroup = this.optionGroup
      if (!currentGroup){
        return this.options
      }
      return this.options.filter(function(option){
        if (!option.group){
          return true
        }
        return option.group === currentGroup
      })
    },
    isValidLocalValueForGroup(){
      if (!this.localValue || !this.optionGroup){
        return true
      }
      for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
        const option = this.options[optionIdx]
        if (option.value !== this.localValue){
          continue
        }
        return option.group === this.optionGroup
        
      }
      return true
    }
  },
  watch:{
    modelValue:{
      immediate:true,
      handler(value){
        if (!value && this.localValue){
          this.localValue = ''
          return
        }
        this.localValue = value
      }
    },
    defaultOption(){
      if (this.defaultOption && this.defaultOption.value && !this.modelValue){
        this.localValue = this.defaultOption.value
      }
    },
    optionGroup(newValue, oldValue){
      if (!oldValue || newValue === oldValue || this.isValidLocalValueForGroup){
        return
      }
      this.localValue = this.defaultOption.value
    },
    localValue() {
      if (this.mandatory === true && !this.localValue){
        this.isValid = false
        this.$emit('invalid:modelValue', "Das Feld '" + this.label +"' ist ein Pflichtfeld und muss ausgewählt werden.")
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000)
      }
    }
  },
  methods:{
    resetIsValid(){
      this.isValid = null
    },
  },
  created(){
    if (this.defaultOption && this.defaultOption.value && !this.modelValue){
      this.localValue = this.defaultOption.value
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>