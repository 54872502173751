<template>
    <div>      
        <div class="success-container" v-if="successMessage">
        <span class="success-message">
            {{successMessage}}
        </span>
        </div>
        <div class="error-container" v-if="errorCode">
        <span class="error-message">
            <span class="error-code">[{{errorCode}}]</span>
            {{errorMessage}}
        </span>
        </div>
        <div class="pending-container" v-if="pendingMessage">
        <span class="pending-message">
            <div class="spinner-grow" role="status"></div>
            {{pendingMessage}}
        </span>
        </div>
        <h4>Organisation - {{ fields.name.value }}</h4>
        <form>
          <div class="row mb-3">
              <label for="name" class="col-sm-4 col-form-label">Name<span class="highlight">*</span></label>  
              <div class="col-sm-8">
                <StringInput
                    name="name" 
                    label="Name"
                    class="form-control"
                    placeholder="Muster GmbH"
                    v-model="fields.name.value"
                    @valid:modelValue="fields.name.isValid = true"
                    @invalid:modelValue="fields.name.isValid = false"
                    @reset:modelValue="fields.name.isValid = null"
                    :min-length="2"
                    mandatory
                />
              </div>
          </div>
          <div class="row mb-3">
              <label for="name" class="col-sm-4 col-form-label">Lizenz-Schlüssel</label>  
              <div class="col-sm-8">
                <StringInput
                    name="apiKey" 
                    label="Lizenz-Schlüssel"
                    class="form-control"
                    placeholder="jp49ksflm92304..."
                    v-model="fields.apiKey.value"
                    @valid:modelValue="fields.apiKey.isValid = true"
                    @invalid:modelValue="fields.apiKey.isValid = false"
                    @reset:modelValue="fields.apiKey.isValid = null"
                    disabled
                />
              </div>
          </div>  
          <div class="row mb-3">
              <label for="name" class="col-sm-4 col-form-label">Lizenz-Secret</label>  
              <div class="col-sm-8">
                <StringInput
                    name="apiSecret" 
                    label="Lizenz-Schlüssel"
                    class="form-control"
                    placeholder="jp49ksflm92304..."
                    v-model="fields.apiSecret.value"
                    @valid:modelValue="fields.apiSecret.isValid = true"
                    @invalid:modelValue="fields.apiSecret.isValid = false"
                    @reset:modelValue="fields.apiSecret.isValid = null"
                    disabled
                />
              </div>
          </div>   
          <h5>Allgemein</h5>          
          <h6>Exposé-Daten</h6>
          <div class="row mb-3">
              <label for="websiteExposeUrl" class="col-4 col-form-label">Exposé-Seite</label>      
              <div class="col-sm-8">
                <StringInput
                  name="websiteExposeUrl" 
                  label="Exposé-Seite"
                  class="form-control"
                  placeholder="https://muster.gmbh/expose"
                  v-model="fields.websiteExposeUrl.value"
                  @valid:modelValue="fields.websiteExposeUrl.isValid = true"
                  @invalid:modelValue="fields.websiteExposeUrl.isValid = false"
                  @reset:modelValue="fields.websiteExposeUrl.isValid = null"
                  :min-length="7"
                  format="^(https?:\/\/)?([\w\-\.])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$"
                />
            </div>
          </div>
          <div class="row mb-3">
              <label for="tenantLogo" class="col-4 col-form-label">Firmenlogo</label>     
              <div class="col-8">
                <FileInput 
                    name="tenantLogo"
                    @update:file="uploadTemporaryLogoFile"
                    @delete:file="unsetLogoFileUUID"
                    :value="logoFileModel"
                    label="Logo hinzufügen"
                    subLabel="Formate: png, jpg, gif, bmp, svg, tiff (Max. 50 MB)"
                />
              </div>
          </div>
          <div class="row mb-3">
              <label for="tenantImmoscoutBadge" class="col-4 col-form-label">Immoscout Siegel</label>     
              <div class="col-8">
                <FileInput 
                    name="tenantImmoscoutBadge"
                    @update:file="uploadTemporaryImmoscoutBadgeFile"
                    @delete:file="unsetImmoscoutBadgeFileUUID"
                    :value="immoscoutBadgeFileModel"
                    label="IS24-Siegel hinzufügen"
                    subLabel="Formate: png, jpg, gif, bmp, svg, tiff (Max. 50 MB)"
                />
              </div>
          </div>
          <div class="row mb-3">
              <label for="immoscoutReviewsUrl" class="col-4 col-form-label">Immoscout Bewertungen</label>      
              <div class="col-sm-8">
                <StringInput
                  name="immoscoutReviewsUrl" 
                  label="Exposé-Seite"
                  class="form-control"
                  placeholder="https://widget.immobilienscout24.de/anbieter/bewertung/95c.."
                  v-model="fields.immoscoutReviewsUrl.value"
                  @valid:modelValue="fields.immoscoutReviewsUrl.isValid = true"
                  @invalid:modelValue="fields.immoscoutReviewsUrl.isValid = false"
                  @reset:modelValue="fields.immoscoutReviewsUrl.isValid = null"
                  :min-length="7"
                  format="^(https?:\/\/)?([\w\-\.])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$"
                />
            </div>
          </div>
          <div class="row mb-3">
              <label for="contactPersonUUID" class="col-sm-4 col-form-label bold">Ansprechpartner</label>            
              <div class="col-sm-8">
                <SelectInput
                    name="contactPersonUUID" 
                    label="Ansprechpartner"
                    class="form-control" 
                    v-model="fields.contactPersonUUID.value"
                    @valid:modelValue="fields.contactPersonUUID.isValid = true"
                    @invalid:modelValue="fields.contactPersonUUID.isValid = false"
                    @reset:modelValue="fields.contactPersonUUID.isValid = null"
                    :options="fields.contactPersonUUID.options"
                />
              </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-8 offset-sm-4">
              <button            
                class="btn btn-sec form-control"
                type="button"
                @click="navigateToPersonManagement"
              >
                Ansprechpartner bearbeiten
              </button>
            </div>
          </div>      
          <h6>Kontakt</h6>
          <div class="row mb-3">
            <label for="name" class="col-sm-4 col-form-label">E-Mail Adresse<span class="highlight">*</span></label>  
            <div class="col-sm-8">
              <StringInput
                name="emailAddress" 
                label="E-Mail Adresse"
                class="form-control"
                placeholder="info@muster.gmbh"
                v-model="fields.emailAddress.value"
                @valid:modelValue="fields.emailAddress.isValid = true"
                @invalid:modelValue="fields.emailAddress.isValid = false"
                @reset:modelValue="fields.emailAddress.isValid = null"
                :min-length="3"
                format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
                mandatory
              />
            </div>
          </div>          
          <div class="row mb-3">
            <label for="phoneNumber" class="col-sm-4 col-form-label">Telefon</label>
            <div class="col-sm-8">
              <StringInput
                name="phoneNumber" 
                label="Telefon"
                placeholder="0631 1234 5678"
                class="form-control" 
                v-model="fields.phoneNumber.value"
                @valid:modelValue="fields.phoneNumber.isValid = true"
                @invalid:modelValue="fields.phoneNumber.isValid = false"
                @reset:modelValue="fields.phoneNumber.isValid = null"
                format="^(\+){0,1}([0-9 \/\-\(\)]){5,40}$"
              />
            </div>
          </div>
          <div class="row mb-3">
              <label for="name" class="col-sm-4 col-form-label">Homepage</label>  
              <div class="col-sm-8">
                <StringInput
                    name="websiteUrl" 
                    label="Homepage"
                    class="form-control"
                    placeholder="https://muster.gmbh"
                    v-model="fields.websiteUrl.value"
                    @valid:modelValue="fields.websiteUrl.isValid = true"
                    @invalid:modelValue="fields.websiteUrl.isValid = false"
                    @reset:modelValue="fields.websiteUrl.isValid = null"
                    :min-length="4"
                />
              </div>
          </div>
          <h6>Adresse</h6>
          <div class="row mb-3">
              <label for="streetName" class="col-sm-4 col-form-label bold">Straße<span class="highlight">*</span></label>  
              <div class="col-sm-8">
                  <StringInput
                      name="streetName" 
                      label="Straße"
                      class="form-control"
                      placeholder="Musterstr."
                      v-model="fields.streetName.value"
                      @valid:modelValue="fields.streetName.isValid = true"
                      @invalid:modelValue="fields.streetName.isValid = false"
                      @reset:modelValue="fields.streetName.isValid = null"
                      :min-length="3"
                      mandatory
                  />
              </div>
          </div>
          <div class="row mb-3">
            <label for="streetNumber" class="col-sm-4 col-form-label bold">Haus-Nr.<span class="highlight">*</span></label>  
            <div class="col-sm-8">
              <StringInput
                name="streetNumber" 
                label="Haus-Nr."
                class="form-control"
                placeholder="2b"
                v-model="fields.streetNumber.value"
                @valid:modelValue="fields.streetNumber.isValid = true"
                @invalid:modelValue="fields.streetNumber.isValid = false"
                @reset:modelValue="fields.streetNumber.isValid = null"
                :min-length="1"
                mandatory
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="zipCode" class="col-sm-4 col-form-label bold">PLZ<span class="highlight">*</span></label>  
            <div class="col-sm-8">
              <StringInput
                name="zipCode" 
                label="PLZ"
                class="form-control"
                placeholder="85325"
                v-model="fields.zipCode.value"
                @valid:modelValue="fields.zipCode.isValid = true"
                @invalid:modelValue="fields.zipCode.isValid = false"
                @reset:modelValue="fields.zipCode.isValid = null"
                :min-length="3"
                :max-length="5"
                mandatory
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="city" class="col-sm-4 col-form-label bold">Ort<span class="highlight">*</span></label>  
            <div class="col-sm-8">
              <StringInput
                name="city" 
                label="Ort"
                class="form-control"
                placeholder="Musterstadt"
                v-model="fields.city.value"
                @valid:modelValue="fields.city.isValid = true"
                @invalid:modelValue="fields.city.isValid = false"
                @reset:modelValue="fields.city.isValid = null"
                :min-length="3"
                mandatory
              />
            </div>
          </div>
          <div class="row mb-3">            
            <div class="offset-6 col-6">
                <button
                  class="btn btn-brand form-control"
                  type="button"
                  @click="saveTenant"
                  :disabled="!areAllFieldsValid"
                >
                  Speichern
                </button>
            </div>
          </div>   
        </form>
    </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import FormMessages from '@/components/forms/features/form-messages.js'

import StringInput from '@/components/inputs/StringInput.vue'
import FileInput from '@/components/inputs/FileInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'

import File from '@/diaspora/models/file.js'
import Tenant from '@/diaspora/models/tenant.js'

import { readProfileTenant } from '@/diaspora/models/queries/tenants.js'

export default {
  name: 'TenantForm',
  components:{
    StringInput,
    FileInput,
    SelectInput,
  },
  data(){
    return {
      areAllFieldsValid: false, 
      messages: new FormMessages(),
      tenantModel: new Tenant(),
      logoFileModel: new File(),
      immoscoutBadgeFileModel: new File(),
    }
  },
  computed:{    
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    },
    fields(){
      return this.tenantModel.fields
    }, 
    logoFileUUID(){
      return this.logoFileModel.fields.uuid.value
    },
    logoFileUUIDTenant(){
      return this.tenantModel.fields.logoFileUUID.value
    },
    immoscoutBadgeFileUUID(){
      return this.immoscoutBadgeFileModel.fields.uuid.value
    },
    immoscoutBadgeFileUUIDTenant(){
      return this.tenantModel.fields.immoscoutBadgeFileUUID.value
    },
  },
  watch:{    
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.tenantModel.areFieldsValid()
      }
    },
    logoFileUUID(){
      if (!this.logoFileUUID){
        this.fields.logoFileUUID.value = ""
        return
      }
      this.fields.logoFileUUID.value = this.logoFileUUID
    },
    async logoFileUUIDTenant(newValue, oldValue){
      console.log("logoFileUUIDTenant", newValue, oldValue)
      if (newValue == oldValue){
        return
      }
      await this.readTenantLogo()
    },
    immoscoutBadgeFileUUID(){
      if (!this.immoscoutBadgeFileUUID){
        this.fields.immoscoutBadgeFileUUID.value = ""
        return
      }
      this.fields.immoscoutBadgeFileUUID.value = this.immoscoutBadgeFileUUID
    },
    async immoscoutBadgeFileUUIDTenant(newValue, oldValue){
      console.log("immoscoutBadgeFileUUIDTenant", newValue, oldValue)
      if (newValue == oldValue){
        return
      }
      await this.readTenantImmoscoutBadge()
    }
  },
  async created(){
    this.readTenant()
    this.readTenantLogo()
    this.readTenantImmoscoutBadge()
    this.readAllPersons()
  },
  methods:{
    async readTenant(){      
      let tenant = {}
      try{
        tenant = await readProfileTenant()
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("IA0011", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.tenantModel.unmarshalJSON(tenant)
    },
    async saveTenant(e){
      if (e){
        e.preventDefault()
      }
      this.messages.reset()

      const tenantData = this.tenantModel.marshalJSON()

      let response = {}
      try{
        response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/profile/tenant", tenantData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("IA0012", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.tenant
      this.tenantModel.unmarshalJSON(responseBody)
      this.messages.success("Organisation erfolgreich gespeichert")
    },
    async uploadTemporaryLogoFile(file){
      this.messages.reset()

      let formData = new FormData()
      formData.append("files", file)
    
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/files/logo", formData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0013", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.logoFileModel.unmarshalJSON(responseBody)
    },
    unsetLogoFileUUID(){
      this.fields.logoFileUUID.value = ''
      this.logoFileModel.unmarshalJSON({})
    }, 
    async readTenantLogo(){
      this.messages.reset()

      if (!this.logoFileUUIDTenant){
        return
      }
    
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/files/" +  this.logoFileUUIDTenant)
      } catch(requestErr) {
        if ((requestErr.response || {}).status == 404){
          return
        }
        const parsedError = services.$err.parseRequestError("ICA0012", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.logoFileModel.unmarshalJSON(responseBody)
    },
    async uploadTemporaryImmoscoutBadgeFile(file){
      this.messages.reset()

      let formData = new FormData()
      formData.append("files", file)
    
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/files/immoscout24Badge", formData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0024", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.immoscoutBadgeFileModel.unmarshalJSON(responseBody)
    },
    unsetImmoscoutBadgeFileUUID(){
      this.fields.immoscoutBadgeFileUUID.value = ''
      this.immoscoutBadgeFileModel.unmarshalJSON({})
    }, 
    async readTenantImmoscoutBadge(){
      this.messages.reset()

      if (!this.immoscoutBadgeFileUUIDTenant){
        return
      }
    
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/files/" +  this.immoscoutBadgeFileUUIDTenant)
      } catch(requestErr) {
        if ((requestErr.response || {}).status == 404){
          return
        }
        const parsedError = services.$err.parseRequestError("ICA0025", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.immoscoutBadgeFileModel.unmarshalJSON(responseBody)
    },
    async readAllPersons(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/person/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0023", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const personOptions = this.parsePersonSelectInputOptions(response.data.all_persons)
      this.fields.contactPersonUUID.options = personOptions
      return
    },
    parsePersonSelectInputOptions(allPersons){
      if (!allPersons){
        return []
      }
      const personOptions = []
      allPersons.forEach(person => {
        if (!person.first_name || !person.last_name){
          return
        }
        personOptions.push({ "label": person.salutation + " "+ person.first_name + " " + person.last_name, "value": person.uuid })
      })
      return personOptions
    },
    navigateToPersonManagement(e){
      if (e){
        e.preventDefault()
      }
      this.$router.push("/person")
    }
  }
}
</script>