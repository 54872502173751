<template>
  <header>
  </header>
  <main id="app">
    <NavSideBar class="main-nav" />
    <body class="main-body" >      
      <NavTopBar class="sub-nav" />
      <router-view :class="{ 'sub-body': true, 'authenticated': isUserAuthenticated }" />
    </body>
  </main>
</template>

<script>
import NavSideBar from '@/components/NavSideBar.vue'
import NavTopBar from '@/components/NavTopBar.vue'

export default {
  name: 'App',
  computed:{
    isUserAuthenticated(){
      return this.$store.getters.isUserAuthenticated
    }
  },
  components:{
    NavTopBar,
    NavSideBar
  }
}
</script>

<style>
#app {
  display: flex;
  width: 100%;
  height: 100%;

  overflow: hidden;
}
</style>
