<template>
  <div
    class="file-input-container"
  >
    <div
      class="file-upload-container"
      :class="{'dragging': isOverFileUploadDropZone}"
    >
      <input
        type="file"
        :name="name"
        ref="fileInput"
        class="form-control file-input"
        @change="handleNewFile"
        @dragover="handleDragOverFileUpload"
        @dragleave="handleDragLeaveFileUpload"
      >
      <div
        v-show="!valueThumbnailURL"
        class="file-input-body"
      >
        <div class="file-input-label">
          <UploadArrowIcon 
            class="file-input-icon"
          />
          {{ label }}
        </div>  
        <div class="file-input-sub-label" v-if="subLabel"> 
          {{subLabel}}
        </div>
      </div> 
      <div
        v-show="valueThumbnailURL"
        class="thumbnail-preview-container"
      >
        <img :src="valueThumbnailURL" />  
      </div>
    </div>
    <div
      class="file-controls"
      v-if="valueThumbnailURL"
    >        
      <span   
        class="btn btn-icon-warn small" 
        role="button"
        @click="triggerDeleteFile"
      >
        <TrashIcon />
      </span>
    </div>
  </div>
</template>

<script>
import UploadArrowIcon from '@/components/icons/UploadArrowIcon.vue'
import TrashIcon from  '@/components/icons/TrashIcon.vue'

import File from '@/diaspora/models/file.js'

export default {
  name: 'FileInput',
  emits:[
    'update:file',
    'delete:file',
  ],
  components:{
    UploadArrowIcon,
    TrashIcon
  },
  props:{
    name:{
      type: String,
      required: true,
    },
    value:{
      type: Object,
      default: new File(),
    },
    label:{
      type: String,
      default: "Datei Hochladen"
    },
    subLabel:{
      type: String,
    }
  },  
  data(){
    return {
      uploadFile: null,
      isOverFileUploadDropZone: false,
      fileInputValue: null,
    }
  },
  computed:{    
    valueThumbnailURL(){
      if (!(((this.value || {}).fields || {}).relativePathThumbnail || {}).value){
        return ""
      }
      return process.env.VUE_APP_PUBLIC_BASE_URL + this.value.fields.relativePathThumbnail.value
    }
  },
  methods:{
    handleNewFile(event){
      const fileList = event.target.files
      if (fileList.length == 0){
        this.uploadFile = null
        this.$refs.fileInput.value = null
        return
      }
      this.isOverFileUploadDropZone = false
      this.uploadFile = fileList[0]
    },
    handleDragOverFileUpload (event) {
      event.preventDefault()

      this.isOverFileUploadDropZone = true
    },
    handleDragLeaveFileUpload (event) {
      event.preventDefault()

      this.isOverFileUploadDropZone = false
    },
    triggerDeleteFile(event){
      event.preventDefault()
      
      this.$emit('delete:file', this.value)
    }
  },
  watch:{      
    uploadFile(){
      if (!this.uploadFile){
        return
      }
      this.$emit('update:file', this.uploadFile)
      this.uploadFile = null
      this.$refs.fileInput.value = null
    },
  }
}
</script>

<style scoped>
.file-input-container{
  border: 1px solid lightgray;
  background-color: white;
  height: 150px;
  overflow: hidden;
  position: relative;
}
.file-upload-container .file-input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}
.file-upload-container:hover,
.file-upload-container.dragging{
  background-color: var(--co-hl-bg);
  cursor: pointer;
}
.file-input-container *{
  max-height: 100%;
  display: block;
}

.file-upload-container,
.thumbnail-preview-container{
  height: inherit;
}

.thumbnail-preview-container > img{
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
.file-input-body{
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.file-input-body .file-input-label{
  width: 100%;
  font-size: 1.5rem;
}
.file-input-body .file-input-label .file-input-icon{
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
}
.file-controls {
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 20;
}
.file-controls *{
  display: unset;
}
.file-controls > .btn{
  margin: 2px;
}
</style>