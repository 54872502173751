import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import PasswordField from '@/diaspora/fields/password_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class User extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:           new UUIDField(),
            personUUID:     new StringField(),
            email:          new StringField(true),
            password:       new PasswordField(),
            role:           new StringField(true, [
                { label: 'Administrator', value: 'admin', },
                { label: 'Führungskraft', value: 'manager' },
                { label: 'Benutzer',      value: 'user', default: true },
            ]),
        }
    }
}

export default User