<template>
  <div class="container form-container">
    <LoginForm class="col-10 col-md-6 col-lg-4" />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>
