<template>
  <div class="container">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <br/>
    <h3>Übersicht - {{ tenantName }}</h3>
    <br/>
    <SubscriptionWidget />
    <ChannelUsageOverviewWidget />
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import SubscriptionWidget from '@/components/widgets/SubscriptionWidget.vue'
import ChannelUsageOverviewWidget from '@/components/widgets/ChannelUsageOverviewWidget.vue'

import { readProfileTenant } from '@/diaspora/models/queries/tenants.js'

import FormMessages from '@/components/forms/features/form-messages.js'

export default {
  name: 'Dashboard',
  data(){
    return {
      tenantName: '',
      messages: new FormMessages()
    }
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    }
  },
  async created(){
    await this.readProfileTenantName()
  },
  methods:{    
    async readProfileTenantName(){
      let tenant = {}
      try{
        tenant = await readProfileTenant()
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("IA0011", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.tenantName = tenant.name
      return true
    },
  },
  components:{
    SubscriptionWidget,
    ChannelUsageOverviewWidget,
  }
}
</script>

<style>
</style>

