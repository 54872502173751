import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class Person extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                       new UUIDField(),
            tenantUUID:                 new UUIDField(),
            profilePictureFileUUID:     new UUIDField(),
            emailAddress:               new StringField(),
            phoneNumber:                new StringField(),
            salutation:                 new StringField(false, [
                { label: 'Herr',    value: 'Herr' },
                { label: 'Frau',    value: 'Frau' },
            ]),
            firstName:                  new StringField(),
            lastName:                   new StringField(),
            immoscout24ID:              new StringField(),
        }
    }
}

export default Person