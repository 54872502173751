class PasswordField{    
    constructor(isMandatory = false){
        this.value = ''
        this.isValid = null
        this.isMandatory = isMandatory
    }
    
    isZeroValue(){
        return this.value === ''
    }

    resetValue(){
        this.value = ''
    }
}


export default PasswordField