<template>
  <div id="person-{{ fields.uuid.value }}">
    <h4>Ansprechpartner {{ operationTitle }}</h4>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div class="pending-container" v-if="pendingMessage">
      <span class="pending-message">
        <div class="spinner-grow" role="status"></div>
        {{pendingMessage}}
      </span>
    </div>
    <form> 
        <div class="row mb-3">
            <label for="salutation" class="col-sm-4 col-form-label">Anrede</label>
            <div class="col-sm-8">
              <SelectInput
                  name="salutation" 
                  label="Anrede"
                  class="form-control" 
                  v-model="fields.salutation.value"
                  @valid:modelValue="fields.salutation.isValid = true"
                  @invalid:modelValue="fields.salutation.isValid = false"
                  @reset:modelValue="fields.salutation.isValid = null"
                  :options="fields.salutation.options"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="firstName" class="col-sm-4 col-form-label">Vorname</label>  
            <div class="col-sm-8">
              <StringInput
                  name="firstName" 
                  label="Vorname"
                  class="form-control"
                  placeholder="Max"
                  v-model="fields.firstName.value"
                  @valid:modelValue="fields.firstName.isValid = true"
                  @invalid:modelValue="fields.firstName.isValid = false"
                  @reset:modelValue="fields.firstName.isValid = null"
                  :min-length="2"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="lastName" class="col-sm-4 col-form-label">Nachname</label>  
            <div class="col-sm-8">
              <StringInput
                  name="lastName" 
                  label="lastName"
                  class="form-control"
                  placeholder="Mustermann"
                  v-model="fields.lastName.value"
                  @valid:modelValue="fields.lastName.isValid = true"
                  @invalid:modelValue="fields.lastName.isValid = false"
                  @reset:modelValue="fields.lastName.isValid = null"
                  :min-length="2"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="emailAddress" class="col-sm-4 col-form-label">E-Mail Adresse</label>  
            <div class="col-sm-8">
              <StringInput
                  name="emailAddress" 
                  label="E-Mail"
                  class="form-control"
                  placeholder="max@mustermann.de"
                  v-model="fields.emailAddress.value"
                  @valid:modelValue="fields.emailAddress.isValid = true"
                  @invalid:modelValue="fields.emailAddress.isValid = false"
                  @reset:modelValue="fields.emailAddress.isValid = null"
                  :min-length="3"
                  format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="phoneNumber" class="col-sm-4 col-form-label">Telefon</label>
            <div class="col-sm-8">
              <StringInput
                  name="phoneNumber" 
                  label="Telefon"
                  placeholder="0631 1234 5678"
                  class="form-control" 
                  v-model="fields.phoneNumber.value"
                  @valid:modelValue="fields.phoneNumber.isValid = true"
                  @invalid:modelValue="fields.phoneNumber.isValid = false"
                  @reset:modelValue="fields.phoneNumber.isValid = null"
                  format="^(\+){0,1}([0-9 \/\-\(\)]){5,40}$"
              />
            </div>
        </div>      
        <div class="row mb-3">
            <label for="profilePicture" class="col-4 col-form-label">Profilbild</label>     
            <div class="col-8">
              <FileInput 
                  name="profilePicture"
                  @update:file="uploadTemporaryProfilePictureFile"
                  @delete:file="unsetProfilePictureFileUUID"
                  :value="profilePictureFileModel"
                  label="Profilbild hinzufügen"
                  subLabel="Formate: png, jpg, gif, bmp, svg, tiff (Max. 50 MB)"
              />
            </div>
        </div>
        <div class="row mb-3 gx-1">
          <div class="col-6">
            <button
              class="btn btn-brand form-control"
              type="button"
              @click="cancelEdit"
            >
              Abbrechen
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-brand form-control"
              type="button"
              @click="savePerson"
              :disabled="!areAllFieldsValid"
            >
              Speichern
            </button>
          </div>
        </div>
    </form>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import StringInput from  '@/components/inputs/StringInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'
import FileInput from '@/components/inputs/FileInput.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import Person from '@/diaspora/models/person.js'
import File from '@/diaspora/models/file.js'

export default {
  name: 'PersonForm',
  emits:[
    'success:modelEdit',
    'cancelled:modelEdit',
  ],
  data(){
    return {
      areAllFieldsValid: false, 
      personModel: new Person(),
      profilePictureFileModel: new File(),
      messages: new FormMessages(),
    }
  },
  props:{ 
    personUUID: {
      type: String,
    }
  },
  async created(){
    this.readPerson()
    this.readPersonProfilePicture()
  },
  computed:{
    fields(){
      return this.personModel.fields
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    },
    profilePictureFileUUIDPerson(){
      return this.fields.profilePictureFileUUID.value
    },    
    profilePictureFileUUID(){
      return this.profilePictureFileModel.fields.uuid.value
    },
    operationTitle(){
      if (this.personUUID){
        return "bearbeiten"
      }
      return "erstellen"
    },
  },
  watch:{    
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.personModel.areFieldsValid()
      }
    },
    profilePictureFileUUID(){
      if (!this.profilePictureFileUUID){
        this.fields.profilePictureFileUUID.value = ""
        return
      }
      this.fields.profilePictureFileUUID.value = this.profilePictureFileUUID
    },
    async profilePictureFileUUIDPerson(newValue, oldValue){
      console.log("profilePictureFileUUIDPerson", newValue, oldValue)
      if (newValue == oldValue){
        return
      }
      await this.readPersonProfilePicture()
    },
    personUUID(){      
      this.readPerson()
      // this.readPersonProfilePicture()
    }
  },
  methods:{
    cancelEdit(e){
      e.preventDefault()
      
      this.$emit('cancelled:modelEdit')
    },
    async readPerson(){
      if (!this.personUUID){
        services.$log.warn("PersonForm.readPerson", "Skipping read of person with empty uuid")
        return
      }
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/person/" + this.personUUID)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0002", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.person
      this.personModel.unmarshalJSON(responseBody)
    },
    async savePerson(e){
      if (e){
        e.preventDefault()
      }
      if(this.personUUID){
        await this.updatePerson()
        return
      }
      await this.createPerson()
    },    
    async createPerson(){
      this.messages.reset()

      const personData = this.personModel.marshalJSON()

      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/person", personData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0021", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.person
      this.personModel.unmarshalJSON(responseBody)
      this.messages.success("Ansprechpartner erfolgreich erstellt")
      await services.$sleep.seconds(1)
      this.$emit("success:modelEdit")
    },
    async updatePerson(){
      this.messages.reset()

      const personData = this.personModel.marshalJSON()

      let response = {}
      try{
        response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/person/" + this.personUUID, personData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0003", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.person
      this.personModel.unmarshalJSON(responseBody)
      this.messages.success("Ansprechpartner erfolgreich gespeichert")
      await services.$sleep.seconds(1)
      this.$emit("success:modelEdit")
    },
    async uploadTemporaryProfilePictureFile(file){
      this.messages.reset()

      let formData = new FormData()
      formData.append("files", file)
    
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/files/profilePicture", formData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0014", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.profilePictureFileModel.unmarshalJSON(responseBody)
    },
    unsetProfilePictureFileUUID(){
      this.fields.profilePictureFileUUID.value = ''
      this.profilePictureFileModel.unmarshalJSON({})
    }, 
    async readPersonProfilePicture(){
      this.messages.reset()

      if (!this.profilePictureFileUUIDPerson){
        return
      }
    
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/files/" +  this.profilePictureFileUUIDPerson)
      } catch(requestErr) {
        if ((requestErr.response || {}).status == 404){
          return
        }
        const parsedError = services.$err.parseRequestError("ICA0012", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.profilePictureFileModel.unmarshalJSON(responseBody)
    },
    /*async readPersonProfilePicture(){
      this.messages.reset()
    
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/files/" + /profile-picture")
      } catch(requestErr) {
        if ((requestErr.response || {}).status == 404){
          return
        }
        const parsedError = services.$err.parseRequestError("ICA0020", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.profilePictureFileModel.unmarshalJSON(responseBody)
    },
    async savePersonProfilePicture(file){
      this.messages.reset()
      this.messages.pending('Der Upload wird bearbeitet. Es könnte noch einen Moment dauern... (Browser-Tab nicht schließen)')

      let formData = new FormData()
      formData.append("files", file)
    
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/files/" + this.relatedType + "/profile-picture", formData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0019", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      const responseBody = response.data.file
      this.profilePictureFileModel.unmarshalJSON(responseBody)
      this.messages.success("Profilbild erfolgreich hochgeladen")
    },
    async deletePersonProfilePicture(){
      this.messages.reset()

      const isConfirmed = confirm("Sicher, dass das Profilbild gelöscht werden soll?")
      if (!isConfirmed){
        return
      }

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/files/" + this.relatedType + "/profile-picture")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0018", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      
      this.profilePictureFileModel = new File()
      this.messages.success("Profilbild erfolgreich gelöscht")
    },    */
  },
  components:{
    StringInput,
    FileInput,
    SelectInput
  }
}
</script>

<style scoped>
#person-fields .form-group{
  margin-top:2em;
}
#person-fields .btn{
  margin-top:4em;
}
#person-fields .form-group label{
  display: block;
  text-align: left;
  margin: 5px 0px;
}
</style>
