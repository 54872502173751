<template>
  <div>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th>Anrede</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Email</th>
          <th>Telefon</th>
          <th>IS24</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="person in sortedPersons"
          :key="person.uuid"
        >
          <td>{{ person.salutation }}</td>
          <td>{{ person.first_name }}</td>
          <td>{{ person.last_name }}</td>
          <td>{{ person.email_address }}</td>
          <td>{{ person.phone_number }}</td>
          <td><CheckBubbleIcon v-if="person.immoscout24_id" /></td>
          <td>
            <button
              class="btn btn-brand"
              type="button"
              @click="editPerson($event, person)"
            >
              Bearbeiten
            </button>
          </td>
          <td>
            <button
              class="btn btn-brand"
              type="button"
              @click="deletePerson($event, person)"
            >
              Löschen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import CheckBubbleIcon from '@/components/icons/CheckBubbleIcon.vue'

export default {
  name: 'PersonManagementTable',
  emits: ['triggered:modelEdit'],
  data(){
    return {
      persons: [],
      successMessage: '',
      errorCode: '',
      errorMessage: ''
    }
  },
  async created(){
    await this.readAllPersons()
  },
  methods:{
    async readAllPersons(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/person/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0004", requestErr)
        this.setErrorMessage(parsedError.code, parsedError.message)
        return false
      }
      this.persons = response.data.all_persons
      return true
    },
    async deletePerson(e, person){
      if (!person.uuid){
        this.setErrorMessage("ICA0006", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("ICA0007", "delete person cannot be executed, invalid uuid")
        return false
      }
      const isConfirmed = confirm("Sicher, dass der Ansprechpartner '"+person.salutation+" "+person.first_name+" "+person.last_name+"' gelöscht werden soll?")
      if (!isConfirmed){
        return false
      }

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/person/" + person.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0005", requestErr)
        this.setErrorMessage(parsedError.code, parsedError.message)
        return false
      }
      this.setSuccessMessage("Ansprechpartner '"+person.salutation+" "+person.first_name+" "+person.last_name+"' wurde erfolgreich gelöscht.")
     
      const readSuccess = await this.readAllPersons()
      if (!readSuccess){
        return false
      }
      return true
    },
    editPerson(e, person){
      this.$emit('triggered:modelEdit', person.uuid)
    },
    personLastNameComparator(first, second) {
      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (first.last_name == ""){
        return orderSecondBeforeFirst
      }
      if (second.last_name == ""){
        return orderFirstBeforeSecond
      }
      if (first.last_name > second.last_name){
        return orderSecondBeforeFirst
      }
      if (first.last_name < second.last_name){
        return orderFirstBeforeSecond
      }
      return orderEqual
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    setSuccessMessage(message){
      window.setTimeout(this.resetSuccessMessage, 1500)
      this.successMessage = message
      this.errorCode = ''
      this.errorMessage = ''
    },
    resetSuccessMessage(){
      this.successMessage = ''
    },
    setErrorMessage(code, message){
      this.successMessage = ''
      this.errorCode = code
      this.errorMessage = message
    }
  },
  computed:{
    sortedPersons(){
      const sorted = this.cloneArray(this.persons)
      return sorted.sort(this.personLastNameComparator)
    }
  },
  components:{
    CheckBubbleIcon
  }
}
</script>

<style>
</style>
