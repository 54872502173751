<template>
  <div>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th>Email</th>
          <th>Rolle</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in sortedUsers"
          :key="user.uuid"
        >
          <td>{{ user.email }}</td>
          <td>{{ formattedUserRole(user.role) }}</td> 
          <td>
            <button
              class="btn btn-brand"
              type="button"
              @click="editUser($event, user)"
            >
              Bearbeiten
            </button>
          </td>
          <td>
            <button
              class="btn btn-brand"
              type="button"
              @click="deleteUser($event, user)"
            >
              Löschen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

export default {
  name: 'UserManagementTable',
  emits: ['triggered:modelEdit'],
  data(){
    return {
      users: [],
      successMessage: '',
      errorCode: '',
      errorMessage: ''
    }
  },
  async created(){
    await this.readAllUsers()
  },
  methods:{
    async readAllUsers(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/user/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0004", requestErr)
        this.setErrorMessage(parsedError.code, parsedError.message)
        return false
      }
      let users = response.data.all_users
      users = users.filter(this.isNonProfileUser)
      this.users = users
      return true
    },
    async deleteUser(e, user){
      if (!user.uuid){
        this.setErrorMessage("ICA0006", "Etwas ist schiefgelaufen. Der ausgewählte Datensatz konnte nicht zugeordnet werden.")
        services.$log.fatal("ICA0007", "delete user cannot be executed, invalid id")
        return false
      }
      const isConfirmed = confirm("Sicher, dass der Benutzer '"+user.email+"' gelöscht werden soll?")
      if (!isConfirmed){
        return false
      }

      try{
        await services.$http.delete(process.env.VUE_APP_CLIENT_NAME, "/user/" + user.uuid)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0005", requestErr)
        this.setErrorMessage(parsedError.code, parsedError.message)
        return false
      }
      this.setSuccessMessage("Benutzer '"+user.email+"' wurde erfolgreich gelöscht.")
     
      const readSuccess = await this.readAllUsers()
      if (!readSuccess){
        return false
      }
      return true
    },
    editUser(e, user){
      this.$emit('triggered:modelEdit', user)
    },
    formattedUserRole(userRole){
      const roleLabels = {
        admin: 'Administrator',
        manager: 'Führungskraft',
        user: 'Benutzer',
      }
      if (!roleLabels[userRole]) {
        return userRole
      }
      return roleLabels[userRole]
    },
    userRoleComparator(first, second) {
      const firstPrio = this.getRolePriority(first.role)
      const secondPrio = this.getRolePriority(second.role)

      const orderFirstBeforeSecond = -1
      const orderSecondBeforeFirst = 1
      const orderEqual = 0

      if (firstPrio == -1){
        return orderSecondBeforeFirst
      }
      if (secondPrio == -1){
        return orderFirstBeforeSecond
      }
      if (firstPrio > secondPrio){
        return orderSecondBeforeFirst
      }
      if (firstPrio < secondPrio){
        return orderFirstBeforeSecond
      }
      return orderEqual
    },
    isNonProfileUser(user){
      const profileUserEmail = this.$store.getters.userEmail
      return user.email != profileUserEmail
    },
    getRolePriority(roleName){
      const rolePriorities = [
        'admin',
        'manager',
        'user'
      ]
      for (let roleIdx = 0; roleIdx < rolePriorities.length; roleIdx++) {
        const prioRole = rolePriorities[roleIdx];
        if (prioRole == roleName){
          return roleIdx
        }        
      }
      return -1
    },
    cloneArray(cloneSource){
      return [...cloneSource]
    },
    setSuccessMessage(message){
      window.setTimeout(this.resetSuccessMessage, 1500)
      this.successMessage = message
      this.errorCode = ''
      this.errorMessage = ''
    },
    resetSuccessMessage(){
      this.successMessage = ''
    },
    setErrorMessage(code, message){
      this.successMessage = ''
      this.errorCode = code
      this.errorMessage = message
    }
  },
  computed:{
    sortedUsers(){
      const sorted = this.cloneArray(this.users)
      return sorted.sort(this.userRoleComparator)
    }
  },
  components:{
  }
}
</script>

<style>
</style>
