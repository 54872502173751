import Model from '@/diaspora/model.js'

import IntegerField from '@/diaspora/fields/integer_field.js'
import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '../fields/uuid_field'

class File extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                   new UUIDField(),
            tenantUUID:             new UUIDField(),
            name:                   new StringField(true),
            size:                   new IntegerField(),
            type:                   new StringField(),     
            relativePath:           new StringField(),      
            relativePathThumbnail:  new StringField(),                      
        }
    }
}

export default File