<template>
  <div class="container">
    <br/>
    <h3>Admin-Einstellungen</h3>
    <nav class="nav-tabbed">
      <div class="tab btn btn-brand-inv" :class="{'active': isCurrentTabCredentials}" @click="this.currentTab = 'Credentials'">
        <KeyIcon />
        Zugangsdaten
      </div>
      <div class="tab btn btn-brand-inv" :class="{'active': isCurrentTabOrganizationData}" @click="this.currentTab = 'OrganizationData'">
        <BuildingsIcon />
        Organisation
      </div>
      <div class="tab btn btn-brand-inv" :class="{'active': isCurrentTabOrganizationStyles}" @click="this.currentTab = 'OrganizationStyles'">
        <PalletteIcon />
        Styling
      </div>
    </nav>
    <div v-show="isCurrentTabCredentials">
      <CredentialsImmoscoutForm 
        class="col-12 col-md-8 col-xl-6"
      />
    </div>
    <div v-show="isCurrentTabOrganizationData">
      <SubscriptionWidget />
      <TenantForm
        class="col-12 col-md-8 col-xl-6"
      />
    </div>
    <div v-show="isCurrentTabOrganizationStyles">
      <TenantStylesForm
        class="col-12 col-md-8 col-xl-6"
      />
    </div>
  </div>
</template>

<script>
import KeyIcon from '@/components/icons/KeyIcon.vue'
import BuildingsIcon from '@/components/icons/BuildingsIcon.vue'
import PalletteIcon from '@/components/icons/PalletteIcon.vue'

import CredentialsImmoscoutForm from '@/components/forms/CredentialsImmoscoutForm.vue'
import TenantForm from '@/components/forms/TenantForm.vue'
import TenantStylesForm from '@/components/forms/TenantStylesForm.vue'
import SubscriptionWidget from '@/components/widgets/SubscriptionWidget.vue'


export default {
  name: 'Admin',
  data(){
    return {
      currentTab: 'Credentials',
    }
  },
  computed:{
    isCurrentTabCredentials(){
      return this.currentTab === 'Credentials'
    },
    isCurrentTabOrganizationData(){
      return this.currentTab === 'OrganizationData'
    },
    isCurrentTabOrganizationStyles(){
      return this.currentTab === 'OrganizationStyles'
    }
  },
  components: {
    BuildingsIcon,
    KeyIcon,
    PalletteIcon,
    CredentialsImmoscoutForm,
    TenantForm,
    TenantStylesForm,
    SubscriptionWidget,
  },
}
</script>

<style>
.nav-tabbed{
  display: flex;
  margin-bottom: 1.5rem;
  background:#fafafade;
  padding-bottom: 1em;
}
.tab{
  display: flex;
  max-width: 200px;
  height: 50px;
  border: 1px solid gray;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}
.tab > svg{
  width: 25px;
  height: 25px;
}
</style>
