<template>
  <div class="container">
    <div class="row gx-2">
      <h2>Ansprechpartner</h2>
      <PersonManagementCollection
        class="col-12 col-md-8"
        v-if="isUserAllowedEditUsers"
      />
    </div>
  </div>
</template>

<script>
import PersonManagementCollection from '@/components/collections/PersonManagementCollection.vue'

export default {
  name: 'PersonManagement',
  computed: {
    isUserAllowedEditUsers(){
      return this.$store.getters.isUserAllowedEditUsers
    }
  },
  components:{
    PersonManagementCollection
  }
}
</script>

<style>
</style>
