<template>
<div
    class="widget-container"
>
  <div class="header">
    <h4>API-Nutzung</h4>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-12" v-if="!this.errorMessage">
        <h5>Monatlich</h5>
        <section>
          <h6>Immoscout24</h6>
          <span class="row">
              <label class="col-6">Verbraucht</label>
              <span class="col-6 text-end">{{ formattedAPICalls }}</span>
          </span>
          <span class="row">
              <label class="col-6">Verbleibend</label>
              <span class="col-6 text-end">{{ formattedRemainingAPICalls }}</span>
          </span>
          <hr />
          <span class="row">
              <label class="col-6">Insgesamt</label>
              <span class="col-6 text-end">{{ formattedAPICallLimit }}</span>
          </span>
          <span class="row">
              <label class="col-6">Benachrichtigungsintervall</label>
              <span class="col-6 text-end">{{ formattedAPICallNotificationInterval }}</span>
          </span>
        </section>
      </div>
      <div class="col-12" v-if="this.errorMessage">
        <div class="error-container relative">
          <span class="error-message">
            <span class="error-code">[{{errorCode}}]</span>
            {{errorMessage}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { services } from '@digiscape/js-core'

import FormMessages from '@/components/forms/features/form-messages.js'

import ChannelAPIUsageOverview from '@/diaspora/derivatives/channel_api_usage_overview.js'

export default {
  name: 'ChannelUsageOverviewWidget',
  data(){
    return {
      messages: new FormMessages(),     
      apiUsageOverviewImmoscout24: new ChannelAPIUsageOverview(),
    }
  },
  computed:{
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    },
    formattedAPICalls(){
      return services.$format.integer(this.apiUsageOverviewImmoscout24.fields.apiCalls.value)
    },
    formattedRemainingAPICalls(){
      const remainingAPICalls = this.apiUsageOverviewImmoscout24.fields.apiCallLimit.value - this.apiUsageOverviewImmoscout24.fields.apiCalls.value
      return services.$format.integer(remainingAPICalls)
    },
    formattedAPICallLimit(){
      return services.$format.integer(this.apiUsageOverviewImmoscout24.fields.apiCallLimit.value)
    },
    formattedAPICallNotificationInterval(){
      return services.$format.integer(this.apiUsageOverviewImmoscout24.fields.apiCallNotificationInterval.value)
    }
  },
  async created(){
    await this.readAllAPIUsageOverviews()
  },
  methods:{
    async readAllAPIUsageOverviews(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/sync-status/statistics/channel-usage")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0011", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return false
      }
      this.apiUsageOverviewImmoscout24.unmarshalJSON(response.data.Immoscout24)
      return true
    },
  }
}
</script>

<style scoped>
#sync-errors-link{
  position: absolute;
  top: 0px;
  right: 5px;
}

.widget-container .header{
  position: relative;
}
</style>