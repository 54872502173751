import { services } from '@digiscape/js-core'

class Marshallable {
    constructor() {
        this.fields = {}
    }

    marshalJSON(){
        const exportData = {}
        for (const fieldName in this.fields) {
            const exportFieldName = services.$strcase.convertToSnakeCase(fieldName)
            const fieldRef = this.fields[fieldName]
            if (fieldRef.isMandatoryField){
                exportData[exportFieldName] = fieldRef.value
                continue
            }
            if (fieldRef.isZeroValue() || !fieldRef.value){
                continue
            }
            exportData[exportFieldName] = fieldRef.value
        }
        return exportData
    }

    unmarshalJSON(importData){
        for (const fieldName in this.fields) {
            const fieldRef = this.fields[fieldName]
            const importFieldName = services.$strcase.convertToSnakeCase(fieldName)
            if (!importData){
                fieldRef.resetValue()
                continue
            }
            if (!Object.prototype.hasOwnProperty.call(importData, importFieldName)) {
                fieldRef.resetValue()
                continue
            }
            if (fieldRef.isMandatoryField){
                fieldRef.value = importData[importFieldName]
                fieldRef.isValid = true
                continue
            }
            if (!importData[importFieldName]){
                continue
            }
            fieldRef.value = importData[importFieldName]
            fieldRef.isValid = true
        }
    }
    
    areFieldsValid(){
        for (const fieldName in this.fields) {
          const fieldRef = this.fields[fieldName]
          if (fieldRef.isValid === false) {
            return false
          }
          if (fieldRef.isMandatory === true && !fieldRef.isValid) {
            return false
          }
        }
        return true
      }
}

export default Marshallable