import { createStore } from 'vuex';
import { jwt } from '@/auth/jwt.js';

export const store = createStore({
    state(){
        return{
            token: '',
            email: '',
            permissions: []
        }
    },
    mutations: {
        updateUser(state, { token, email }){
            state.token = token
            window.localStorage.token = token

            if (!token){
                state.email = email
                window.localStorage.email = email
                return
            }

            const tokenContents = jwt.parse(token)
            state.permissions = tokenContents.user_permissions
            if (!email){                
                email = tokenContents.user_email
            }
            state.email = email
            window.localStorage.email = email
        }
    },
    getters:{
        userEmail(state){
            return state.email
        },
        isUserAuthenticated(state){
            return state.email !== '' 
                && state.email !== undefined
        },
        isUserAllowedEditUsers(state){
            if (!state.permissions){
                return false
            }
            if (state.permissions.length === 0){
                return false
            }
            for (let permissionIdx = 0; permissionIdx < state.permissions.length; permissionIdx++) {
                const permission = state.permissions[permissionIdx]
                if (permission == 'edit_users'){
                    return true
                }                
            }
            return false
        },
        isUserAllowedEditImmoscoutCredentials(state){
            if (!state.permissions){
                return false
            }
            if (state.permissions.length === 0){
                return false
            }
            for (let permissionIdx = 0; permissionIdx < state.permissions.length; permissionIdx++) {
                const permission = state.permissions[permissionIdx]
                if (permission == 'edit_immoscout24_credentials'){
                    return true
                }                
            }
            return false
        }
    },
    actions:{        
        clearUser(context){
            context.commit({
                type: 'updateUser',
                token: '',
                email: ''
              })
        }
    }
})