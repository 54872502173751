import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class Tenant extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                       new UUIDField(),
            contactPersonUUID:          new StringField(),
            logoFileUUID:               new StringField(),
            immoscoutBadgeFileUUID:     new StringField(),
            stripeID:                   new StringField(),
            name:                       new StringField(true),
            companyName:                new StringField(true),
            emailAddress:               new StringField(true),
            phoneNumber:                new StringField(),
            streetName:                 new StringField(true),
            streetNumber:               new StringField(true),
            zipCode:                    new StringField(true),
            city:                       new StringField(true),
            websiteExposeUrl:           new StringField(),         
            websiteUrl:                 new StringField(),            
            immoscoutReviewsUrl:        new StringField(),            
            billingName:                new StringField(),
            billingEmailAddress:        new StringField(),
            billingPhoneNumber:         new StringField(),
            billingStreetName:          new StringField(),
            billingStreetNumber:        new StringField(),
            billingZipCode:             new StringField(),
            billingCity:                new StringField(),
            billingTaxId:               new StringField(),
            apiKey:                     new StringField(),
            apiSecret:                  new StringField(),
        }
    }
}

export default Tenant