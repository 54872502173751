class StringField{    
    constructor(isMandatory = false, options = []){
        this.value = ''
        this.isValid = null
        this.isMandatory = isMandatory
        this.options = options
    }
    
    displayValue(){
        for (let optionIdx = 0; optionIdx < this.options.length; optionIdx++) {
            const option = this.options[optionIdx]
            if (option.value === this.value){
                return option.label
            }
        }
        return ''
    }

    isZeroValue(){
        return this.value === ''
    }

    resetValue(){
        this.value = ''
    }
}


export default StringField