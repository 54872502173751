import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'
import FloatField from '@/diaspora/fields/float_field.js'
import TimestampField from '@/diaspora/fields/timestamp_field.js'

class Subscription extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:               new UUIDField(),
            stripeID:           new StringField(),
            tenantUUID:         new UUIDField(),
            productUUID:        new UUIDField(),
            name:               new StringField(true),
            status:             new StringField(true, [
                { label: 'Testzeitraum',        value: 'trialing', default: true },
                { label: 'Aktiv',               value: 'active' },
                { label: 'Storniert',           value: 'canceled' },
                { label: 'Zahlung Offen',       value: 'unpaid' },
                { label: 'Zahlung Gescheitert', value: 'incomplete' },
                { label: 'Abgelaufen',          value: 'incomplete_expired' },
                { label: 'Überfällig',          value: 'past_due' },
            ]),
            billingInterval:    new StringField(true, [
                { label: 'Monatlich', value: 'Monthly', default: true },
                { label: 'Jährlich',  value: 'Yearly' },
            ]),
            billingPrice:       new FloatField(true),
            contractedAt:       new TimestampField(),
            startedAt:          new TimestampField(),
            cancelledAt:        new TimestampField(),
            endedAt:            new TimestampField(),
            currentPeriodStart: new TimestampField(),
            currentPeriodEnd:   new TimestampField(),
        }
    }
}

export default Subscription