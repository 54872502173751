<template>
  <div class="container">
    <div class="row gx-2">
      <h2>Benutzerverwaltung</h2>
      <UserProfileForm
        class="container-fluid col-10 col-xl-5"
      />
      <UserManagementCollection
        class="container-fluid col-10 col-xl-6"
        v-if="isUserAllowedEditUsers"
      />
    </div>
  </div>
</template>

<script>
import UserManagementCollection from '@/components/collections/UserManagementCollection.vue'
import UserProfileForm from '@/components/forms/UserProfileForm.vue'

export default {
  name: 'UserProfile',
  computed: {
    isUserAllowedEditUsers(){
      return this.$store.getters.isUserAllowedEditUsers
    }
  },
  components:{
    UserProfileForm,
    UserManagementCollection
  }
}
</script>

<style>
</style>
