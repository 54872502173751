<template>
  <div id="logout" class="container">
    <div class="col-10 col-md-6">
      <h3>Sie wurden erfolgreich abgemeldet.</h3>
      <div>In {{timer}} Sekunden werden Sie weitergeleitet...</div>
    </div>
  </div>
</template>

<script>
import {services} from '@digiscape/js-core'

export default {
  name: 'Logout',
  data(){
    return {
      timer: 2
    }
  },
  created(){
    this.$store.dispatch('clearUser')
    services.$http.clearAuthHeaderToken(process.env.VUE_APP_CLIENT_NAME)

    window.setTimeout(this.redirectToLogin, this.timer * 1000);
    window.setTimeout(this.decrementTimer, 1000);
  },
  methods:{
    redirectToLogin(){
      this.$router.push('/login')
    },
    decrementTimer(){
      this.timer--
      if (this.timer > 0){
        window.setTimeout(this.decrementTimer, 1000);
      }
    }
  }
}
</script>

<style>
</style>
