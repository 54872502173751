<template>
  <div id="user-creation">
    <h4>Mein Profil</h4>
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <form>
        <h5>Anmeldedaten</h5>      
        <div class="row mb-3">
            <label for="email" class="col-sm-4 col-form-label">E-Mail Adresse</label>  
            <div class="col-sm-8">
              <StringInput
                  name="email" 
                  label="E-Mail"
                  class="form-control"
                  placeholder="max@mustermann.de"
                  v-model="fields.email.value"
                  @valid:modelValue="fields.email.isValid = true"
                  @invalid:modelValue="fields.email.isValid = false"
                  @reset:modelValue="fields.email.isValid = null"
                  :min-length="3"
                  format="^(.+)@(.+){2,}\.([A-z]{2,4})$"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="password" class="col-sm-4 col-form-label">Passwort</label>
            <div class="col-sm-8">
              <PasswordInput
                  name="password" 
                  label="Passwort"
                  class="form-control" 
                  v-model="fields.password.value"
                  @valid:modelValue="fields.password.isValid = true"
                  @invalid:modelValue="fields.password.isValid = false"
                  @reset:modelValue="fields.password.isValid = null"
                  :min-length="3"
              />
            </div>
        </div>
        <div class="row mb-3">
            <label for="personUUID" class="col-sm-4 col-form-label bold">Ansprechpartner</label>            
            <div class="col-sm-8">
              <SelectInput
                  name="personUUID" 
                  label="Ansprechpartner"
                  class="form-control" 
                  v-model="fields.personUUID.value"
                  @valid:modelValue="fields.personUUID.isValid = true"
                  @invalid:modelValue="fields.personUUID.isValid = false"
                  @reset:modelValue="fields.personUUID.isValid = null"
                  :options="fields.personUUID.options"
              />
            </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-8 offset-sm-4">
            <button            
              class="btn btn-sec form-control"
              type="button"
              @click="navigateToPersonManagement"
            >
              Ansprechpartner bearbeiten
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <button
            class="btn btn-brand form-control"
            type="button"
            @click="saveUser"
            :disabled="!areAllFieldsValid"
          >
            Speichern
          </button>
        </div>        
    </form>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import StringInput from  '@/components/inputs/StringInput.vue'
import PasswordInput from  '@/components/inputs/PasswordInput.vue'
import SelectInput from  '@/components/inputs/SelectInput.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import User from '@/diaspora/models/user.js'

export default {
  name: 'UserProfileForm',
  data(){
    return {
      areAllFieldsValid: false, 
      userModel: new User(),
      messages: new FormMessages(),
    }
  },
  async created(){
    this.readUser()
    await this.readAllPersons()
  },
  computed:{
    fields(){
      return this.userModel.fields
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    }
  },
  watch:{    
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.userModel.areFieldsValid()
      }
    }
  },
  methods:{
    async readUser(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0002", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.user
      this.userModel.unmarshalJSON(responseBody)
    },
    async saveUser(e){
      e.preventDefault()

      this.messages.reset()

      const userData = this.userModel.marshalJSON()

      try{
        await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/profile/save", userData)
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0003", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      this.messages.success("Benutzer erfolgreich gespeichert")
    },
    async readAllPersons(){
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/person/all")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0023", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const personOptions = this.parsePersonSelectInputOptions(response.data.all_persons)
      this.fields.personUUID.options = personOptions
      return
    },
    parsePersonSelectInputOptions(allPersons){
      if (!allPersons){
        return []
      }
      const personOptions = []
      allPersons.forEach(person => {
        if (!person.first_name || !person.last_name){
          return
        }
        personOptions.push({ "label": person.salutation + " "+ person.first_name + " " + person.last_name, "value": person.uuid })
      })
      return personOptions
    },
    navigateToPersonManagement(e){
      if (e){
        e.preventDefault()
      }
      this.$router.push("/person")
    }
  },
  components:{
    StringInput,
    PasswordInput,
    SelectInput
  }
}
</script>

<style scoped>
#user-fields .form-group{
  margin-top:2em;
}
#user-fields .btn{
  margin-top:4em;
}
#user-fields .form-group label{
  display: block;
  text-align: left;
  margin: 5px 0px;
}
</style>
