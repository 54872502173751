<template>
    <div>      
        <div class="success-container" v-if="successMessage">
        <span class="success-message">
            {{successMessage}}
        </span>
        </div>
        <div class="error-container" v-if="errorCode">
        <span class="error-message">
            <span class="error-code">[{{errorCode}}]</span>
            {{errorMessage}}
        </span>
        </div>
        <div class="pending-container" v-if="pendingMessage">
        <span class="pending-message">
            <div class="spinner-grow" role="status"></div>
            {{pendingMessage}}
        </span>
        </div>
        <h4>Styling - {{ fields.name.value }}</h4>
        <form>
            <h5>Einfaches Styling</h5>
            <div class="row mb-3">
                <label for="basePrimaryColor" class="col-sm-4 col-form-label">Hauptfarbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="basePrimaryColor" 
                        label="Hauptfarbe"
                        class="form-control"
                        defaultValue="#0a455c"
                        v-model="fields.basePrimaryColor.value"
                        @valid:modelValue="fields.basePrimaryColor.isValid = true"
                        @invalid:modelValue="fields.basePrimaryColor.isValid = false"
                        @reset:modelValue="fields.basePrimaryColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.basePrimaryColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="basePrimaryInvertedColor" class="col-sm-4 col-form-label">Hauptf. (Kontrast)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="basePrimaryInvertedColor" 
                        label="Hauptfarbe"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.basePrimaryInvertedColor.value"
                        @valid:modelValue="fields.basePrimaryInvertedColor.isValid = true"
                        @invalid:modelValue="fields.basePrimaryInvertedColor.isValid = false"
                        @reset:modelValue="fields.basePrimaryInvertedColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.basePrimaryInvertedColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>            
            <div class="row mb-3">
                <label for="baseHighlightColor" class="col-sm-4 col-form-label">Aktionsfarbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseHighlightColor" 
                        label="Aktionsfarbe"
                        class="form-control"
                        defaultValue="#51c0ec"
                        v-model="fields.baseHighlightColor.value"
                        @valid:modelValue="fields.baseHighlightColor.isValid = true"
                        @invalid:modelValue="fields.baseHighlightColor.isValid = false"
                        @reset:modelValue="fields.baseHighlightColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseHighlightColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="baseHighlightInvertedColor" class="col-sm-4 col-form-label">Aktionsf. (Kontrast)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseHighlightInvertedColor" 
                        label="Aktionsf. (Kontrast)"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.baseHighlightInvertedColor.value"
                        @valid:modelValue="fields.baseHighlightInvertedColor.isValid = true"
                        @invalid:modelValue="fields.baseHighlightInvertedColor.isValid = false"
                        @reset:modelValue="fields.baseHighlightInvertedColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseHighlightInvertedColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="baseDarkColor" class="col-sm-4 col-form-label">Dunkle Farbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseDarkColor" 
                        label="Dunkle Farbe"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.baseDarkColor.value"
                        @valid:modelValue="fields.baseDarkColor.isValid = true"
                        @invalid:modelValue="fields.baseDarkColor.isValid = false"
                        @reset:modelValue="fields.baseDarkColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseDarkColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="baseDarkInvertedColor" class="col-sm-4 col-form-label">Dunkle F. (Kontrast)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseDarkInvertedColor" 
                        label="Dunkle F. (Kontrast)"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.baseDarkInvertedColor.value"
                        @valid:modelValue="fields.baseDarkInvertedColor.isValid = true"
                        @invalid:modelValue="fields.baseDarkInvertedColor.isValid = false"
                        @reset:modelValue="fields.baseDarkInvertedColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseDarkInvertedColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="baseLightColor" class="col-sm-4 col-form-label">Helle Farbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseLightColor" 
                        label="Helle Farbe"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.baseLightColor.value"
                        @valid:modelValue="fields.baseLightColor.isValid = true"
                        @invalid:modelValue="fields.baseLightColor.isValid = false"
                        @reset:modelValue="fields.baseLightColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseLightColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="baseLightInvertedColor" class="col-sm-4 col-form-label">Helle F. (Kontrast)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseLightInvertedColor" 
                        label="Helle F. (Kontrast)"
                        class="form-control"
                        defaultValue="#383838"
                        v-model="fields.baseLightInvertedColor.value"
                        @valid:modelValue="fields.baseLightInvertedColor.isValid = true"
                        @invalid:modelValue="fields.baseLightInvertedColor.isValid = false"
                        @reset:modelValue="fields.baseLightInvertedColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseHighlightInvertedColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <h5>Erweitertes Styling</h5>   
            <div class="row mb-3">
                <label for="baseLabelColor" class="col-sm-4 col-form-label">Beschriftungen</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="baseLabelColor" 
                        label="Beschriftungen"
                        class="form-control"
                        defaultValue="#484848"
                        v-model="fields.baseLabelColor.value"
                        @valid:modelValue="fields.baseLabelColor.isValid = true"
                        @invalid:modelValue="fields.baseLabelColor.isValid = false"
                        @reset:modelValue="fields.baseLabelColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.baseLabelColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <h6>Übersichtsfilter</h6>
            <div class="row mb-3">
                <label for="baseFilterValueSliderMode" class="col-sm-4 col-form-label">Slider-Modus</label>                  
                <div class="col-sm-8">            
                    <SelectInput
                        :name="baseFilterValueSliderMode"
                        label="Slider-Modus"
                        class="form-control" 
                        v-model="fields.baseFilterValueSliderMode.value"
                        @valid:modelValue="fields.baseFilterValueSliderMode.isValid = true"
                        @invalid:modelValue="fields.baseFilterValueSliderMode.isValid = false"
                        @reset:modelValue="fields.baseFilterValueSliderMode.isValid = null"
                        :options="fields.baseFilterValueSliderMode.options"
                    />
                </div>
            </div>
            <h6>Übersichtskacheln</h6>    
            <div class="row mb-3">
                <label for="boxBackgroundColor" class="col-sm-4 col-form-label">Hintergründe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="boxBackgroundColor" 
                        label="Hintergründe"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.boxBackgroundColor.value"
                        @valid:modelValue="fields.boxBackgroundColor.isValid = true"
                        @invalid:modelValue="fields.boxBackgroundColor.isValid = false"
                        @reset:modelValue="fields.boxBackgroundColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.boxBackgroundColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>   
            <div class="row mb-3">
                <label for="boxBorderColor" class="col-sm-4 col-form-label">Umrandungen</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="boxBorderColor" 
                        label="Umrandungen"
                        class="form-control"
                        defaultValue="#0a455c"
                        v-model="fields.boxBorderColor.value"
                        @valid:modelValue="fields.boxBorderColor.isValid = true"
                        @invalid:modelValue="fields.boxBorderColor.isValid = false"
                        @reset:modelValue="fields.boxBorderColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.boxBorderColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>   
            <div class="row mb-3">
                <label for="boxHeadingColor" class="col-sm-4 col-form-label">Titelfarbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="boxHeadingColor" 
                        label="Titelfarbe"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.boxHeadingColor.value"
                        @valid:modelValue="fields.boxHeadingColor.isValid = true"
                        @invalid:modelValue="fields.boxHeadingColor.isValid = false"
                        @reset:modelValue="fields.boxHeadingColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.boxHeadingColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="boxHeadingSize" class="col-sm-4 col-form-label">Textgröße (Titel)</label>  
                <div class="col-sm-8">
                    <StringInput
                        name="boxHeadingSize" 
                        label="Überschriften-Text"
                        class="form-control"
                        placeholder="1.4rem"
                        v-model="fields.boxHeadingSize.value"
                        @valid:modelValue="fields.boxHeadingSize.isValid = true"
                        @invalid:modelValue="fields.boxHeadingSize.isValid = false"
                        @reset:modelValue="fields.boxHeadingSize.isValid = null"
                    />
                </div>
            </div>
            <div class="row mb-3">
                <label for="boxHeadingLine" class="col-sm-4 col-form-label">Zeilenhöhe (Titel)</label>  
                <div class="col-sm-8">
                    <StringInput
                        name="boxHeadingLine" 
                        label="Überschriften-Text"
                        class="form-control"
                        placeholder="2rem"
                        v-model="fields.boxHeadingLine.value"
                        @valid:modelValue="fields.boxHeadingLine.isValid = true"
                        @invalid:modelValue="fields.boxHeadingLine.isValid = false"
                        @reset:modelValue="fields.boxHeadingLine.isValid = null"
                    />
                </div>
            </div>
            <div class="row mb-3">
                <label for="boxLabelColor" class="col-sm-4 col-form-label">Beschriftungen</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="boxLabelColor" 
                        label="Beschriftungen"
                        class="form-control"
                        defaultValue="#484848"
                        v-model="fields.boxLabelColor.value"
                        @valid:modelValue="fields.boxLabelColor.isValid = true"
                        @invalid:modelValue="fields.boxLabelColor.isValid = false"
                        @reset:modelValue="fields.boxLabelColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.boxLabelColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>            
            <div class="row mb-3">
                <label for="boxTextColor" class="col-sm-4 col-form-label">Textfarbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="boxTextColor" 
                        label="Titelfarbe"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.boxTextColor.value"
                        @valid:modelValue="fields.boxTextColor.isValid = true"
                        @invalid:modelValue="fields.boxTextColor.isValid = false"
                        @reset:modelValue="fields.boxTextColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.boxTextColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="boxTextSize" class="col-sm-4 col-form-label">Textgröße (Inhalt)</label>  
                <div class="col-sm-8">
                    <StringInput
                        name="boxTextSize" 
                        label="Überschriften-Text"
                        class="form-control"
                        placeholder="1rem"
                        v-model="fields.boxTextSize.value"
                        @valid:modelValue="fields.boxTextSize.isValid = true"
                        @invalid:modelValue="fields.boxTextSize.isValid = false"
                        @reset:modelValue="fields.boxTextSize.isValid = null"
                    />
                </div>
            </div>            
            <h6>Exposé Abschnitt - Überblick</h6>            
            <div class="row mb-3">
                <label for="outlineBackgroundColor" class="col-sm-4 col-form-label">Hintergrund</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="outlineBackgroundColor" 
                        label="Hintergrund"
                        class="form-control"
                        defaultValue="#efeee9"
                        v-model="fields.outlineBackgroundColor.value"
                        @valid:modelValue="fields.outlineBackgroundColor.isValid = true"
                        @invalid:modelValue="fields.outlineBackgroundColor.isValid = false"
                        @reset:modelValue="fields.outlineBackgroundColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.outlineBackgroundColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>   
            <div class="row mb-3">
                <label for="outlineBorderColor" class="col-sm-4 col-form-label">Umrandung</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="outlineBorderColor" 
                        label="Umrandung"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.outlineBorderColor.value"
                        @valid:modelValue="fields.outlineBorderColor.isValid = true"
                        @invalid:modelValue="fields.outlineBorderColor.isValid = false"
                        @reset:modelValue="fields.outlineBorderColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.outlineBorderColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>               
            <div class="row mb-3">
                <label for="outlineLabelColor" class="col-sm-4 col-form-label">Beschriftungen</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="outlineLabelColor" 
                        label="Beschriftungen"
                        class="form-control"
                        defaultValue="#484848"
                        v-model="fields.outlineLabelColor.value"
                        @valid:modelValue="fields.outlineLabelColor.isValid = true"
                        @invalid:modelValue="fields.outlineLabelColor.isValid = false"
                        @reset:modelValue="fields.outlineLabelColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.outlineLabelColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>            
            <div class="row mb-3">
                <label for="outlineTextColor" class="col-sm-4 col-form-label">Textfarbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="outlineTextColor" 
                        label="Titelfarbe"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.outlineTextColor.value"
                        @valid:modelValue="fields.outlineTextColor.isValid = true"
                        @invalid:modelValue="fields.outlineTextColor.isValid = false"
                        @reset:modelValue="fields.outlineTextColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.outlineTextColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <h6>Foto-Carousel</h6>            
            <div class="row mb-3">
                <label for="sliderArrowBackgroundColor" class="col-sm-4 col-form-label">Pfeil-Hintergr.</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderArrowBackgroundColor" 
                        label="Pfeil-Hintergr."
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.sliderArrowBackgroundColor.value"
                        @valid:modelValue="fields.sliderArrowBackgroundColor.isValid = true"
                        @invalid:modelValue="fields.sliderArrowBackgroundColor.isValid = false"
                        @reset:modelValue="fields.sliderArrowBackgroundColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderArrowBackgroundColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>          
            <div class="row mb-3">
                <label for="sliderArrowBackgroundHoverColor" class="col-sm-4 col-form-label">Pfeil-Hintergr. (schwebend)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderArrowBackgroundHoverColor" 
                        label="Pfeil-Hintergr. (schwebend)"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.sliderArrowBackgroundHoverColor.value"
                        @valid:modelValue="fields.sliderArrowBackgroundHoverColor.isValid = true"
                        @invalid:modelValue="fields.sliderArrowBackgroundHoverColor.isValid = false"
                        @reset:modelValue="fields.sliderArrowBackgroundHoverColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderArrowBackgroundHoverColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div> 
            <div class="row mb-3">
                <label for="sliderArrowFillColor" class="col-sm-4 col-form-label">Pfeil-Farbe</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderArrowFillColor" 
                        label="Pfeil-Farbe"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.sliderArrowFillColor.value"
                        @valid:modelValue="fields.sliderArrowFillColor.isValid = true"
                        @invalid:modelValue="fields.sliderArrowFillColor.isValid = false"
                        @reset:modelValue="fields.sliderArrowFillColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderArrowFillColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="sliderArrowFillHoverColor" class="col-sm-4 col-form-label">Pfeil-Farbe (schwebend)</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderArrowFillHoverColor" 
                        label="Pfeil-Farbe (schwebend)"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.sliderArrowFillHoverColor.value"
                        @valid:modelValue="fields.sliderArrowFillHoverColor.isValid = true"
                        @invalid:modelValue="fields.sliderArrowFillHoverColor.isValid = false"
                        @reset:modelValue="fields.sliderArrowFillHoverColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderArrowFillHoverColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="sliderArrowBackgroundHoverColor" class="col-sm-4 col-form-label">Thumbnail-Hintergrund</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderThumbnailBackgroundColor" 
                        label="Thumbnail-Hintergrund"
                        class="form-control"
                        defaultValue="#fbfffe"
                        v-model="fields.sliderThumbnailBackgroundColor.value"
                        @valid:modelValue="fields.sliderThumbnailBackgroundColor.isValid = true"
                        @invalid:modelValue="fields.sliderThumbnailBackgroundColor.isValid = false"
                        @reset:modelValue="fields.sliderThumbnailBackgroundColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderThumbnailBackgroundColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="sliderArrowBackgroundHoverColor" class="col-sm-4 col-form-label">Thumbnail-Umrandung</label>  
                <div class="col-sm-6">
                    <ColorInput
                        name="sliderThumbnailBorderColor" 
                        label="Thumbnail-Umrandung"
                        class="form-control"
                        defaultValue="#000000"
                        v-model="fields.sliderThumbnailBorderColor.value"
                        @valid:modelValue="fields.sliderThumbnailBorderColor.isValid = true"
                        @invalid:modelValue="fields.sliderThumbnailBorderColor.isValid = false"
                        @reset:modelValue="fields.sliderThumbnailBorderColor.isValid = null"
                    />
                </div>
                <div class="col-sm-1 btn-icon-container">                    
                    <button 
                        class="btn btn-icon form-control"
                        type="button"
                        @click="fields.sliderThumbnailBorderColor.value = ''"
                    >
                        <UndoArrowIcon />
                    </button>
                </div>
            </div>
            <div class="row mb-3">            
                <div class="offset-6 col-6">
                    <button
                        class="btn btn-brand form-control"
                        type="button"
                        @click="saveTenantStyles"
                        :disabled="!areAllFieldsValid"
                    >
                    Speichern
                    </button>
                </div>
            </div>   
        </form>
    </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import FormMessages from '@/components/forms/features/form-messages.js'

import TenantStyles from '@/diaspora/models/tenant-styles.js'

import ColorInput from '@/components/inputs/ColorInput.vue'
import StringInput from '@/components/inputs/StringInput.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'

import UndoArrowIcon from  '@/components/icons/UndoArrowIcon.vue'

import { readProfileTenantStyles, saveProfileTenantStyles } from '@/diaspora/models/queries/tenant-styles.js'

export default {
    name: 'TenantStylesForm',
    components:{
        ColorInput,
        StringInput,
        SelectInput,
        UndoArrowIcon,
    },
    data(){
        return {
            areAllFieldsValid: false, 
            messages: new FormMessages(),
            tenantStylesModel: new TenantStyles(),
        }
    },
    computed:{    
        errorCode(){
            return this.messages.errorCode
        },
        errorMessage(){
            return this.messages.errorMessage
        },
        successMessage(){
            return this.messages.successMessage
        },
        pendingMessage(){
            return this.messages.pendingMessage
        },
        fields(){
            return this.tenantStylesModel.fields
        },
        basePrimaryColorValue(){
            return this.fields.basePrimaryColor.value
        },
        baseHighlightColorValue(){
            return this.fields.baseHighlightColor.value
        },
        baseLightColorValue(){
            return this.fields.baseLightColor.value
        },
        baseDarkColorValue(){
            return this.fields.baseDarkColor.value
        }
    },
    watch:{    
        fields: {
            deep:true,
            handler(){
                this.areAllFieldsValid = this.tenantStylesModel.areFieldsValid()
            }
        },
        basePrimaryColorValue(){
            if (!this.basePrimaryColorValue){
                this.fields.basePrimaryTranslucentColor.value = '';
                return;
            }
            this.fields.basePrimaryTranslucentColor.value = this.basePrimaryColorValue + '80';
        },
        baseHighlightColorValue(){
            if (!this.baseHighlightColorValue){
                this.fields.baseHighlightTranslucentColor.value = '';
                return;
            }
            this.fields.baseHighlightTranslucentColor.value = this.baseHighlightColorValue + '80';
        },
        baseLightColorValue(){
            if (!this.baseLightColorValue){
                this.fields.baseLightTranslucentColor.value = '';
                return;
            }
            this.fields.baseLightTranslucentColor.value = this.baseLightColorValue + '80';
        },
        baseDarkColorValue(){
            if (!this.baseDarkColorValue){
                this.fields.baseDarkTranslucentColor.value = '';
                return;
            }
            this.fields.baseDarkTranslucentColor.value = this.baseDarkColorValue + '80';
        }
    },
    async created(){
        this.readTenantStyles()
    },
    methods:{
        async readTenantStyles(){      
            let tenantStylesData = {}
            try{
                tenantStylesData = await readProfileTenantStyles()
            } catch(requestErr) {
                const parsedError = services.$err.parseRequestError("IA0011", requestErr)
                this.messages.error(parsedError.code, parsedError.message)
                return false
            }
            this.tenantStylesModel.unmarshalJSON(tenantStylesData)
        },
        async saveTenantStyles(e){
            if (e){
                e.preventDefault()
            }
            this.messages.reset()

            const reqTenantStylesData = this.tenantStylesModel.marshalJSON()

            let respTenantStylesData = {}
            try{
                respTenantStylesData = await saveProfileTenantStyles(reqTenantStylesData)
            } catch(requestErr) {
                const parsedError = services.$err.parseRequestError("IA0012", requestErr)
                this.messages.error(parsedError.code, parsedError.message)
                return
            }
            this.tenantStylesModel.unmarshalJSON(respTenantStylesData)
            this.messages.success("Organisation-Styling erfolgreich gespeichert")
        },
        addAlphaToHex(hexColor, opacity){
            if (opacity == 0){
                return hexColor + '00'
            }
            const clampedOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
            return hexColor + clampedOpacity.toString(16).toUpperCase();
        }
    },
}
</script>

<style>
.btn-icon-container{
    min-width: 45px;
}
</style>