<template>
  <div id="credentials-immoscout">
    <div class="success-container" v-if="successMessage">
      <span class="success-message">
        {{successMessage}}
      </span>
    </div>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
    <div class="pending-container" v-if="pendingMessage">
      <span class="pending-message">
        <div class="spinner-grow" role="status"></div>
        {{pendingMessage}}
      </span>
    </div>
    <h4>Immoscout24 Zugänge</h4>
    <div
      v-show="fields.finalTokenValue.value" 
      class="col-8 col-xl-6 mb-3 auth-indicator-container"
    >
      <CheckBubbleIcon class="auth-indicator-success" /> Zugang freigeschaltet
    </div>
    <form id="credentials-immoscout-fields">
      <div class="row mb-3">
        <label for="consumerKey" class="col-4 col-form-label">Lizenz-Schlüssel</label>     
        <div class="col-8">   
          <StringInput
            name="consumerKey" 
            id="consumerKey"
            label="Lizenz-Schlüssel"
            class="form-control"
            placeholder="MyGmbHKey"
            v-model="fields.consumerKey.value"
            @valid:modelValue="fields.consumerKey.isValid = true"
            @invalid:modelValue="fields.consumerKey.isValid = false"
            @reset:modelValue="fields.consumerKey.isValid = null"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label for="consumerSecret" class="col-4 col-form-label">Secret-Schlüssel</label>
        <div class="col-8">   
          <PasswordInput
            name="consumerSecret" 
            id="consumerSecret"
            label="Secret-Schlüssel"
            class="form-control"
            v-model="fields.consumerSecret.value"
            @valid:modelValue="fields.consumerSecret.isValid = true"
            @invalid:modelValue="fields.consumerSecret.isValid = false"
            @reset:modelValue="fields.consumerSecret.isValid = null"
          />
        </div>
      </div>
      <div class="row">
        <div class="offset-6 col-6">   
          <button
            class="btn btn-brand form-control"
            type="button"
            @click="saveCredentials"
            :disabled="!areAllFieldsValid"
          >
            Speichern
          </button>
        </div>
      </div>
    </form>
    <div class="error-container" v-if="errorCode">
      <span class="error-message">
        <span class="error-code">[{{errorCode}}]</span>
        {{errorMessage}}
      </span>
    </div>
  </div>
</template>

<script>
import { services } from '@digiscape/js-core'

import StringInput from  '@/components/inputs/StringInput.vue'
import PasswordInput from  '@/components/inputs/PasswordInput.vue'

import FormMessages from '@/components/forms/features/form-messages.js'

import CredentialsImmoscout from '@/diaspora/models/credentials-immoscout.js'

import CheckBubbleIcon from  '@/components/icons/CheckBubbleIcon.vue'

export default {
  name: 'CredentialsImmoscoutForm',
  data(){
    return {
      areAllFieldsValid: false,
      messages: new FormMessages(),      
      credentialsImmoscoutModel: new CredentialsImmoscout()
    }
  },
  async created(){
    await this.loadCredentials()
  },
  computed:{
    fields(){
      return this.credentialsImmoscoutModel.fields
    },
    errorCode(){
      return this.messages.errorCode
    },
    errorMessage(){
      return this.messages.errorMessage
    },
    successMessage(){
      return this.messages.successMessage
    },
    pendingMessage(){
      return this.messages.pendingMessage
    }
  },
  watch:{
    fields: {
      deep:true,
      handler(){
        this.areAllFieldsValid = this.credentialsImmoscoutModel.areFieldsValid()
      }
    }
  },
  methods:{
    async loadCredentials(){
      this.messages.reset()
      let response = {}
      try{
        response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/immoscout24/credentials")
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0009", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const responseBody = response.data.credentials
      this.credentialsImmoscoutModel.unmarshalJSON(responseBody)
    },
    async saveCredentials(event){
      event.preventDefault()
      this.messages.reset()
      let response = {}
      try{
        response = await services.$http.post(process.env.VUE_APP_CLIENT_NAME, "/immoscout24/login", this.credentialsImmoscoutModel.marshalJSON())
      } catch(requestErr) {
        const parsedError = services.$err.parseRequestError("ICA0010", requestErr)
        this.messages.error(parsedError.code, parsedError.message)
        return
      }
      const authURL = response.data.url
      location.href = authURL
    }
  },
  components:{
    StringInput,
    PasswordInput,
    CheckBubbleIcon
  }
}
</script>

<style scoped>
.auth-indicator-container{
  color: var(--co-suc-bg-inv);
  background-color: var(--co-suc-bg-tl);
  border: 2px solid var(--co-suc-brd);
  border-radius: 5px;
  padding: 3px;
}
.auth-indicator-success{
  color: var(--co-suc-brd);
  font-weight: 600;
  width: 24px;
  height: 24px;
}
</style>
