class FloatField{    
    constructor(isMandatory = false){
        this.value = 0.0
        this.isValid = null
        this.isMandatory = isMandatory
    }
    
    isZeroValue(){
        return this.value === 0.0
    }

    resetValue(){
        this.value = 0.0
    }
}


export default FloatField