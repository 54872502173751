<template>
  <input
    v-if="!this.isTextArea"
    type="text" 
    :name="name"
    :placeholder="placeholder"
    :class="[ this.class, {'input-valid': isValid === true, 'input-invalid': isValid === false }]"
    v-model.lazy="localValue"
    :mandatory="mandatory"
    :disabled="disabled"
    :id="baseID + '-input'"
    :list="baseID + '-options'"
  >
  <datalist
    v-if="!this.isTextArea && this.options"
    :id="baseID+ '-options'"
  >
    <option
      v-for="option in options"
      :key="option.label"
      :value="option.value"
    />
  </datalist>
  <textarea
    v-if="this.isTextArea"
    :rows="numRows"
    :id="baseID + '-textarea'"
    :name="name"
    :placeholder="placeholder"
    :class="[ this.class, {'input-valid': isValid === true, 'input-invalid': isValid === false }]"
    v-model.lazy="localValue"
    :mandatory="mandatory"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'StringInput',
  data(){
    return {
      localValue: '',
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "...",
    },
    minLength:{
      type: Number
    },
    maxLength:{
      type: Number
    },
    format:{
      type: String
    },
    mandatory:{
      type: Boolean
    },
    id: {
      type: String
    },
    class: {
      type: String
    },
    resizable: {
      type: Boolean
    },
    textArea: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    numRows: {
      type: Number,
      default: 8,
    },
    options: {
      type: Object
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue', 
    'reset:modelValue'
  ],
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = ''
          return
        }
        this.localValue = value
      }
    },
    localValue(){
      const validateErr = this.validateInput()
      if (validateErr != ""){
        this.isValid = false
        this.$emit('update:modelValue', this.localValue)
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!this.localValue){
        this.isValid = null
        this.$emit('update:modelValue', '')
        this.$emit('reset:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  computed:{
    isTextArea(){
      if (this.textArea === true){
        return true
      }
      if (this.resizable === false){
        return false
      }
      if (this.minLength && this.minLength >= 40){
        return true
      }
      if (this.maxLength && this.maxLength >= 40){
        return true
      }
      return this.localValue.length >= 40
    },
    baseID(){
      if (this.id){
        return this.id
      }
      return this.name
    }
  },
  methods:{
    validateInput(){
      if (!this.localValue){
        return ""
      }
      if (typeof this.localValue !== 'string' && !(this.localValue instanceof String)) {
        return "Das Feld '" + this.label +"' darf nur gültige Zeichenfolgen enthalten."
      }
      if (this.minLength && this.localValue.length < this.minLength){
        return "Das Feld '"+ this.label +"' muss mindestens "+this.minLength+" Zeichen lang sein."
      }
      if (this.maxLength && this.localValue.length > this.maxLength){
        return "Das Feld '"+ this.label +"' darf höchstens "+this.maxLength+" Zeichen lang sein."
      }
      if (this.format){
        const validator = new RegExp(this.format)
        if (!validator.test(this.localValue)){
          return "Das Feld '"+ this.label +"' entspricht nicht dem erwarteten Format '"+this.format+"'."
        }
      }

      return ""
    },
    resetIsValid(){
      this.isValid = null
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>