<template>
  <input 
    type="password" 
    :name="name"
    :placeholder="placeholder"
    :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    v-model.lazy="localValue"
  >
</template>

<script>
export default {
  name: 'PasswordInput',
  data(){
    return {
      localValue: '',
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    placeholder:{
      type: String,
      default: "*********",
    },
    minLength:{
      type: Number
    },
    maxLength:{
      type: Number
    },
    format:{
      type: String
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue', 
    'reset:modelValue'
  ],
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = ''
          return
        }
        this.localValue = value
      }
    },
    localValue(){
      const validateErr = this.validateInput()
      if (validateErr != ""){
        this.isValid = false
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!this.localValue){
        this.isValid = null
        this.$emit('update:modelValue', '')
        this.$emit('reset:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    validateInput(){
      if (!this.localValue){
        return ""
      }
      if (typeof this.localValue !== 'string' && !(this.localValue instanceof String)) {
        return "Das Feld '" + this.label +"' darf nur gültige Zeichenfolgen enthalten."
      }
      if (this.minLength && this.localValue.length < this.minLength){
        return "Das Feld '"+ this.label +"' muss mindestens "+this.minLength+" Zeichen lang sein."
      }
      if (this.maxLength && this.localValue.length > this.maxLength){
        return "Das Feld '"+ this.label +"' darf höchstens "+this.maxLength+" Zeichen lang sein."
      }
      if (this.format){
        const validator = new RegExp(this.format)
        if (!validator.test(this.localValue)){
          return "Das Feld '"+ this.label +"' entspricht nicht dem erwarteten Format '"+this.format+"'."
        }
      }

      return ""
    },
    resetIsValid(){
      this.isValid = null
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid green;
  background-color: lightgreen;
}
.input-invalid{
  border: 1px solid red;  
  background-color: lightpink;
}
</style>