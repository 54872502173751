<template>
  <div class="container">
    <div class="row">
      <div class="col-4">
        <button
          class="btn btn-sec form-control"
          type="button"
          v-if="currentComponent == 'PersonManagementTable'"
          @click="navigateToPreviousPage"
        >
          Zurück
        </button>
      </div>
      <div class="col-8">
        <button
          class="btn btn-brand form-control"
          type="button"
          v-if="currentComponent == 'PersonManagementTable'"
          @click="showPersonForm"
        >
          Ansprechpartner erstellen
        </button>
      </div>
    </div>
    <PersonManagementTable
      v-if="currentComponent == 'PersonManagementTable'"
      @triggered:modelEdit="showPersonFormWithUUID"
    />
    <PersonForm
      v-if="currentComponent == 'PersonForm'"
      @success:modelEdit="showPersonManagementTable"
      @cancelled:modelEdit="showPersonManagementTable"
      :personUUID="editPersonUUID"
    />
  </div>
</template>

<script>
import PersonManagementTable from '@/components/tables/PersonManagementTable.vue'

import PersonForm from '@/components/forms/PersonForm.vue'

export default {
  name: 'PersonManagementCollection',
  data(){
    return {
      currentComponent: 'PersonManagementTable',
      editPersonUUID: '',
    }
  },
  methods:{
    showPersonManagementTable(){
      this.currentComponent = 'PersonManagementTable'
    },
    showPersonForm(e){
      if (e){
        e.preventDefault()
      }
      this.editPersonUUID = ''
      this.currentComponent = 'PersonForm'
    },
    navigateToPreviousPage(e){
      if (e){
        e.preventDefault()
      }
      this.$router.back()
    },
    showPersonFormWithUUID(personUUID){
      this.editPersonUUID = personUUID
      this.currentComponent = 'PersonForm'
    }
  },
  components:{
    PersonManagementTable,
    PersonForm
  }
}
</script>

<style>
</style>
