<template>
    <input 
        type="color"
        :name="name" 
        v-model.lazy="localValue"
        :class="{ 'input-valid': isValid === true, 'input-invalid': isValid === false }"
    />
  </template>

<script>
export default {
  name: 'ColorInput',
  data(){
    return {
      localValue: "",
      isValid: null,
    }
  },
  props:{
    modelValue:{
      type: String,
      required: true
    },
    defaultValue:{
      type: String,
      default: ''
    },
    name:{
      type: String
    },
    label:{
      type: String
    },
    mandatory:{
      type: Boolean
    }
  },
  emits: [
    'update:modelValue', 
    'invalid:modelValue', 
    'valid:modelValue', 
    'reset:modelValue'
  ],
  watch:{
    modelValue:{      
      immediate:true,
      handler(value){
        if (!value){
          this.localValue = this.defaultValue
          return
        }
        this.localValue = value
      }
    },
    localValue(){
      const validateErr = this.validateInput()
      if (validateErr != ""){
        this.isValid = false
        this.$emit('update:modelValue', this.localValue)
        this.$emit('invalid:modelValue', validateErr)
        return
      }
      if (!this.localValue || this.localValue == this.defaultValue){
        this.isValid = null
        this.$emit('update:modelValue', '')
        this.$emit('reset:modelValue')
        return
      }
      this.isValid = true
      this.$emit('update:modelValue', this.localValue)
      this.$emit('valid:modelValue')
    },
    isValid(){
      if(this.isValid === true){
        window.setTimeout(this.resetIsValid, 1000);
      }
    }
  },
  methods:{
    validateInput(){
        if (!this.localValue){
            return ""
        }
        if (typeof this.localValue !== 'string' && !(this.localValue instanceof String)) {
            return "Das Feld '" + this.label +"' darf nur gültige Zeichenfolgen enthalten."
        }
        return ""
    },
    resetIsValid(){
      this.isValid = null
    }
  }
}
</script>

<style scoped>
.input-valid{
  border: 1px solid var(--co-suc-brd, green);
  background-color: var(--co-suc-bg, lightgreen);
}
.input-invalid{
  border: 1px solid var(--co-err-brd, red);  
  background-color: var(--co-err-bg, lightpink);
}

input[type="color"]{
  height: 2.35rem;
}
</style>