<template>
    <nav id="ntb" class="container" v-if="isUserAuthenticated">
      <router-link 
        to="/logout"
        class="btn-icon-hl"
      >
        <ArrowOutIcon />
      </router-link>
      <router-link 
        to="/profile"
        class="btn-icon"
        :class="{'active': currentPageRoute == '/profile' }"
      >
        <ProfileIcon />
      </router-link>
      <router-link 
        v-if="isUserAdmin"
        to="/admin"
        class="btn-icon"
        :class="{'active': currentPageRoute == '/admin' }"
      >
        <GearIcon />
      </router-link>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'

import ArrowOutIcon from  '@/components/icons/ArrowOutIcon.vue'
import ProfileIcon from  '@/components/icons/ProfileIcon.vue'
import GearIcon from  '@/components/icons/GearIcon.vue'

export default {
  name: 'NavTopBar',
  computed:{
    isUserAuthenticated(){
      return this.$store.getters.isUserAuthenticated
    },
    isUserAdmin(){
      return this.$store.getters.isUserAllowedEditImmoscoutCredentials
    },
    currentUser(){
      return this.$store.state.email
    },
    currentPageRoute(){
      const route = useRoute()
      return route.path
    },
  },
  components: {
    ArrowOutIcon,
    ProfileIcon,
    GearIcon
  }
}
</script>

<style scoped>
#ntb{
  display: flex;
  width: 85%;
  height: 50px;
  line-height: 50px;
}
#ntb > *{
  margin: 5px;
  border-radius: 5px;
  width:  40px;
  height: 40px;
}
#ntb-user{
  padding-left: 2rem;
  font-size: 1.2rem;
}

#ntb-user .user{
  padding: .5rem 1rem;
}
</style>
