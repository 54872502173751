<template>
    <nav
      id="nsb"
      role="navigation"
    >
        <div id="nsb-container">
            <div id="nsb-logo-container">
                <a href="https://app.immoscape.de">
                    <img 
                        id="logo"
                        src="@/assets/immoscape-logo.png"
                        alt="Immoscape"
                        style="max-width: 200px;"
                    />
                </a>
            </div>
            <div id="nsb-content" v-if="isUserAuthenticated">
                <ul>
                    <li 
                        v-for="page in pages"
                        :key="page.key"
                    >
                        <router-link 
                          :to="page.route"
                          :class="{ 'nav-link': true, 'active': currentPageRoute == page.route }"
                        >
                          <SpeedometerIcon v-if="page.key === 'dashboard'" />
                          {{ page.label }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'

import SpeedometerIcon from  '@/components/icons/SpeedometerIcon.vue'

export default {
  name: 'NavSideBar',
  data() {
    return {
      pages: [
        {
          key: "dashboard",
          route: "/",
          label: "Home"
        }
      ]
    }
  },
  computed:{
    isUserAuthenticated(){
      return this.$store.getters.isUserAuthenticated
    },
    currentPageRoute(){
      const route = useRoute()
      return route.path
    },
  },
  components:{
    SpeedometerIcon
  }
}
</script>

<style scoped>
#nsb{    
    max-width: min(20vw, 500px);
    max-height: 100vh;
    min-width:  30px;
    min-height: 75px;
    
    padding: 10px;
    background-color: var(--co-pri);
    color: var(--co-bg-sec-inv);    
}

#nsb-content{
  margin-top: 5rem;
}

#nsb-logo-container{
    width: 60%;
    margin-left:auto;
    margin-right:auto;
}

#nsb-logo-container > a{
    width: 100%;
}
#nsb-logo-container > a > img{
    width: 100%;
}

#nsb-content > ul{  
  list-style: none;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@media (min-width: 1200px) {  /* xl */
  #nsb-content > ul{
    font-size: 1.5rem;
  }
  #nsb-content .nav-link > svg{    
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 1199.98px) {  /* lg */
  #nsb-content > ul{
    padding-left: 0;
  }
}
@media (max-width: 991.98px) { /* md */
  #nsb {    
    max-width: min(20vw, 100px);
  }
  #nsb-content > ul{
    font-size: 0rem;
  }
  #nsb-content .nav-link > svg{
    width: 100%;
    height: 3rem;
  }
  #nsb-content .nav-link{
    padding: 0.5rem 0.3rem;
  }
}
#nsb-content .nav-link{
    color: var(--co-hl-inv);
}
#nsb-content .nav-link:hover{
    color: var(--co-bg-sec);
}
#nsb-content .nav-link.active{
    color: var(--co-hl);
}
#nsb-content .nav-link.active:hover{
    color: var(--co-bg-sec);
}
</style>
