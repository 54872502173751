import Derivative from '@/diaspora/derivative.js'

import IntegerField from '@/diaspora/fields/integer_field.js'

class ChannelAPIUsageOverview extends Derivative{
    constructor() {
        super() 
        this.fields = {
            apiCalls:                       new IntegerField(),
            apiCallLimit:                   new IntegerField(),
            apiCallNotificationInterval:    new IntegerField(),
        }
    }
}

export default ChannelAPIUsageOverview