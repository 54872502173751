import { services } from '@digiscape/js-core'

async function readProfileTenantStyles(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile/styles")
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.tenant_styles
    return responseBody
}

async function saveProfileTenantStyles(tenantStylesData){
    const response = await services.$http.put(process.env.VUE_APP_CLIENT_NAME, "/profile/styles", tenantStylesData)
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.tenant_styles
    return responseBody
}

export { readProfileTenantStyles, saveProfileTenantStyles }