import { createWebHistory, createRouter } from 'vue-router'

import { services } from '@digiscape/js-core'

import { store } from '@/store/store.js'

import Dashboard from '@/components/views/Dashboard.vue'
import Login from '@/components/views/Login.vue'
import Logout from '@/components/views/Logout.vue'
import UserProfile from '@/components/views/UserProfile.vue'
import PersonManagement from '@/components/views/PersonManagement.vue'
import Admin from '@/components/views/Admin.vue'

let vueRouter = createRouter({
    history: createWebHistory(),
    routes:[
        { 
            path: '/:pathMatch(.*)*',
            component: Dashboard,
            meta:{
                authenticate: true,
            }
        },
        { 
            path: '/login',
            component: Login
        },
        { 
            path: '/logout',
            component: Logout
        },
        {
            path: '/profile',
            component: UserProfile,
            meta:{
                authenticate: true,
            }
        },
        {
            path: '/person',
            component: PersonManagement,
            meta:{
                authenticate: true,
            }
        },
        {
            path: '/admin',
            component: Admin,
            meta:{
                authenticate: true,
                permissions: [
                    'edit_immoscout24_credentials',
                    'edit_tenant',
                ]
            }
        }
    ]
})

vueRouter.beforeEach(function(to){
    const isAuthRequired = to.matched.some(route => route.meta.authenticate)
    if (isAuthRequired){
        const token = store.state.token
        if (!token){
            return {
                path: '/login',
                params: {nextUrl:to.fullPath}
            }
        }
        services.$http.storeAuthHeaderToken(process.env.VUE_APP_CLIENT_NAME, token)
        let hasAllPermissions = true
        const userPermissions = store.state.permissions
        to.matched.forEach(function(route){
            const requiredPermissions = route.meta.permissions
            if (!requiredPermissions || !hasAllPermissions){
                return 
            }
            for (let permissionIdx = 0; permissionIdx < requiredPermissions.length; permissionIdx++) {
                const permission = requiredPermissions[permissionIdx]
                if (!userPermissions.includes(permission)){
                    hasAllPermissions = false
                    return
                }
            }
        })
        if (!hasAllPermissions){
            return {
                path: '/',
                params: {nextUrl:to.fullPath}
            }
        }
        return true
    }
    return true
})

export let router = vueRouter