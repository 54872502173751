import { services } from '@digiscape/js-core'

async function readProfileTenant(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile/tenant")
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.tenant
    return responseBody
}

export { readProfileTenant }