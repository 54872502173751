import { services } from '@digiscape/js-core'

async function readProfileSubscription(){
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile/subscription")
    if (response.status === 204){
        return {}
    }
    const responseBody = response.data.subscription
    return responseBody
}

async function getProfileStripeSubscriptionURL(){    
    const response = await services.$http.get(process.env.VUE_APP_CLIENT_NAME, "/profile/subscription/stripe-url")
    const authURL = response.data.url
    return authURL
}

export { readProfileSubscription, getProfileStripeSubscriptionURL }