import Model from '@/diaspora/model.js'

import StringField from '@/diaspora/fields/string_field.js'
import UUIDField from '@/diaspora/fields/uuid_field.js'

class TenantStyles extends Model{
    constructor() {
        super() 
        this.fields = {
            uuid:                           new UUIDField(),
            name:                           new StringField(),

            baseFilterValueSliderMode:      new StringField(false, [
                { label: "Text Eingabe-Felder", value: "Bounds", default: true },
                { label: "Ohne Felder (nur Slider)", value: "Range" },
            ]),

            baseLabelColor:                 new StringField(),
            baseDarkColor:                  new StringField(),
            baseDarkInvertedColor:          new StringField(),
            baseDarkTranslucentColor:       new StringField(),
            baseHighlightColor:             new StringField(),
            baseHighlightInvertedColor:     new StringField(),
            baseHighlightTranslucentColor:  new StringField(),
            baseLightColor:                 new StringField(),
            baseLightInvertedColor:         new StringField(),
            baseLightTranslucentColor:      new StringField(),
            basePrimaryColor:               new StringField(),
            basePrimaryInvertedColor:       new StringField(),
            basePrimaryTranslucentColor:    new StringField(),
            
            boxBackgroundColor:             new StringField(),
            boxBorderColor:                 new StringField(),
            boxLabelColor:                  new StringField(),
            boxHeadingColor:                new StringField(),
            boxHeadingLine:                 new StringField(),
            boxHeadingSize:                 new StringField(),
            boxTextColor:                   new StringField(),
            boxTextSize:                    new StringField(),

            ctaHeadingColor:                new StringField(),

            outlineBackgroundColor:         new StringField(),
            outlineBorderColor:             new StringField(),
            outlineLabelColor:              new StringField(),
            outlineTextColor:               new StringField(),
            
            sliderArrowBackgroundColor:         new StringField(),
            sliderArrowBackgroundHoverColor:    new StringField(),
            sliderArrowFillColor:               new StringField(),
            sliderArrowFillHoverColor:          new StringField(),
            sliderThumbnailBackgroundColor:     new StringField(),
            sliderThumbnailBorderColor:         new StringField(),
        }
    }
}

export default TenantStyles